import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import axios from "axios";
import "./c_a_Style3.css";
import "./c_a_section.css";
import apiurl from "../apiUrl";



const filterOptions = ["Both", "English", "हिन्दी"];

const headerOptions = [
  { id: "currentAffair", name: "Current Affair" },
  { id: "quizCurrentAffair", name: "Quiz Current Affair" },
  { id: "gk", name: "G.K." },
];

const sidebarOptions = ["center", "Haryana", "state2", "state3"];
const categoryOptions = ["All", "daily", "weekly", ",onthly"];

const CurrentAffair = () => {
  const [selectedHeader, setSelectedHeader] = useState("currentAffair");
  const [selectedSidebar, setSelectedSidebar] = useState("center");
  const [category, setCategory] = useState("all");
  const [selectDate, setSelectDate] = useState("");
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [newsData, setNewsData] = useState([]);
  
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Reset today's time for strict comparison
  const [selectedDate, setSelectedDate] = useState(today);

  // Format the date as YYYY-MM-DD
  const formatDate = (date) => date.toISOString().split("T")[0];

 


  const handleDateChange = async (newDate) => {
    try {
      setSelectedDate(newDate);
      console.log("Selected Date:", selectedSidebar); // Debugging

      // Example API call
      const response = await axios.get(`${apiurl}/news/get/current-affairs`, {
        params: {
          header: selectedHeader,
          location: selectedSidebar,
          category,
          selectDate :newDate,
        },
      });
      setNewsData(response.data);
      console.log("API Response:", response.data);
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const changeDate = (days) => {
    const newDate = new Date(selectedDate);
    newDate.setDate(newDate.getDate() + days);

    // Prevent selecting dates after today
    if (newDate <= today) {
      handleDateChange(newDate);
    }
  };

  // Check if the selected date is today
  const isToday = formatDate(selectedDate) === formatDate(today);

  


  const fetchFilteredData = async (date) => {
    
    console.log("this is select date", selectDate);
    try {
      const response = await axios.get(`${apiurl}/news/get/current-affairs`, {
        params: {
          header: selectedHeader,
          location: selectedSidebar,
          category,
          selectDate: date,
        },
      });
      setNewsData(response.data);
      console.log("API Response:", response.data);
    //   filteredNewsData();
    //   alert("Data fetched successfully!");
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("Failed to fetch data.");
    }
  };

  useEffect(() => {
    const today = new Date();
    const formattedDate = today.toISOString().split("T")[0]; 
    console.log(today)
    setSelectDate(today.toISOString().split("T")[0]); // Format: YYYY-MM-DD
    fetchFilteredData (formattedDate)
  }, []);

  
  
  const handleGoClick = () => {
    fetchFilteredData(selectDate);
    setIsTableVisible(false);
  };

  const handleFilterClick = () => {
    
    setIsDropdownOpen((prevState) => !prevState);
  };

  const handleFilterSelect = (filter) => {
    setSelectedFilter(filter);
    setIsDropdownOpen(false); // Close dropdown
    console.log("Selected Filter:", filter);
  };

  const filteredNewsData = newsData.filter(item => {
    if (selectedFilter === "All") {
      return true; // Return all items if "All" is selected
    } else {
      return item.language === selectedFilter; // Filter by language
    }
  });

 

  
  


  return (
    <div className="news-container">
       <div>
            <Link to="/current-affair/add">
                <button>Add News</button>
            </Link>
        </div>
        {/* Header Section */}
        <header className="news-header">
            {headerOptions.map((option) => (
            <div
                key={option.id}
                className={`news-header-item ${
                selectedHeader === option.id ? "selected" : ""
                }`}
                onClick={() => setSelectedHeader(option.id)}
            >
                {option.name}
            </div>
            ))}
        </header>

      {/* Sub-header Section */}
        <div className="sub-main-news">

            <div className="sub-news-header">
                <select
                    className="category-select"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                >
                    {categoryOptions.map((option) => (
                        <option key={option} value={option}>
                            {option.charAt(0).toUpperCase() + option.slice(1)}  {/* Capitalized text for display */}
                        </option>
                    ))}
                </select>

                <input
                type="date"
                className="date-input"
                value={selectDate}
                onChange={(e) => setSelectDate(e.target.value)}
                />
                <button className="go-button" onClick={handleGoClick}> Go </button>
            </div>

        {/* Dropdown and Table Toggle */}
            <div className="sub-res-news-header">
                <button
                className="filter-res-toggle-button"
                onClick={() => setIsTableVisible(!isTableVisible)}
                >
                {isTableVisible ? "Hide Category" : "Show Category"}
                </button>
                {isTableVisible && (
                <div className="res-cat-table">
                    <select
                    className="res-category-select"
                    value={category}
                    onChange={(e) => setCategory(e.target.value)}
                    >
                    {categoryOptions.map((option) => (
                        <option key={option} value={option}>
                        {option.charAt(0).toUpperCase() + option.slice(1)}
                        </option>
                    ))}
                    </select>
                
                    <input
                    type="date"
                    className="res-date-input"
                    value={selectDate}
                    onChange={(e) => setSelectDate(e.target.value)}
                    />
                    <button className="res-go-button" onClick={handleGoClick}>
                    Go
                    </button>
                </div>
                )}
            </div>


            <div className="filter-container">
            <button
                className="filter-button"
                onClick={handleFilterClick}
                aria-expanded={isDropdownOpen}
                >
                {isDropdownOpen ? "Close" : "Filter"}
            </button>

            {isDropdownOpen && (
                <ul className="filter-dropdown">
                {filterOptions.map((option) => (
                    <li
                    key={option}
                    className="filter-option"
                    onClick={() => handleFilterSelect(option)}
                    >
                    {option}
                    </li>
                ))}
                </ul>
            )}


            </div>
      </div>


      

      

      <div className="news-content">
        {/* Sidebar Section */}
        <aside className="news-sidebar">
          {sidebarOptions.map((option) => (
            <div
              key={option}
              className={`news-sidebar-item ${
                selectedSidebar === option ? "selected" : ""
              }`}
              onClick={() => setSelectedSidebar(option)}
            >
              {option.charAt(0).toUpperCase() + option.slice(1)}
            </div>
          ))}
        </aside>


       

        {/* Main Content */}
        {/* Main Content */}
        <div className="news-section-box">
        {filteredNewsData.map((item, key) => (
            <div className="news-card" key={item._id || `${item.id}-${key}`}> {/* Use a unique key */}
            <h3>{key + 1}.</h3>
            <div className="news-card-head">
                <img src={`http://127.0.0.1:8005/uploads/${item.images}`} alt={item.title} />
                <div>
                <h3>{item.title}</h3>
                <h4>{item.date}</h4>
                <p>{item.description}</p>
                </div>
            </div>

            {item.details && (
                <ul>
                {item.details.map((detail, idx) => (
                    <li key={idx}>{detail}</li>
                ))}
                </ul>
            )}

            {item.list && (
                <div>
                <h4>Details</h4>
                <table>
                    <thead>
                    <tr>
                        <th>S.No</th>
                        {[...new Set(item.list.flatMap((entry) => Object.keys(entry)))].map((key) => (
                        <th key={key}>{key}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {item.list.map((row, rowIndex) => {
                        const allKeys = [...new Set(item.list.flatMap((entry) => Object.keys(entry)))];
                        return (
                        <tr key={rowIndex}>
                            <td>{rowIndex + 1}</td>
                            {allKeys.map((key, colIndex) => (
                            <td key={colIndex}>{row[key] || ""}</td>
                            ))}
                        </tr>
                        );
                    })}
                    </tbody>
                </table>
                </div>
            )}
            </div>
        ))}
        </div>






      </div>
        <div className="date-selector">
      <button className="arrow-button" onClick={() => changeDate(-1)}>
        &larr;
      </button>

      <div className="date-display">
        {isToday ? (
          <span>Today</span>
        ) : (
          <input
            type="date"
            value={formatDate(selectedDate)}
            onChange={(e) => {
              const newDate = new Date(e.target.value);
              if (newDate <= today) handleDateChange(newDate); // Call API when date changes
            }}
            max={formatDate(today)} // Enable selecting up to today
          />
        )}
      </div>

      <button
        className="arrow-button"
        onClick={() => changeDate(1)}
        disabled={isToday} // Disable when it's today
      >
        &rarr;
      </button>
        </div>


    </div>
  );
};

export default CurrentAffair;
