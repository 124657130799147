import React, { useState } from "react";
import "./addExamStyle.css";
import apiurl from '../apiUrl.js';

const TestListForm = ({ exam, examName, onSubmit, onClose}) => {
  const [formData, setFormData] = useState({
    serialNumber: "",
    testType: "Full Test",
    numberOfQuestions: 0,
    marks: "",
    time: "",
    date: "",
    language: [],
    syllabus: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    setFormData((prevState) => {
      const updatedLanguages = checked
        ? [...prevState.language, value]
        : prevState.language.filter((lang) => lang !== value);

      return { ...prevState, language: updatedLanguages };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData); // Pass form data to parent component's submit handler
  };

  return (
    <form className="addExamListForm" onSubmit={handleSubmit}>
      <button className="closeAddExam" onClick={onClose}>✖</button>
      {/* Serial Number */}
      <div>
        <label>Serial Number:</label>
        <input
          type="number"
          name="serialNumber"
          value={formData.serialNumber}
          onChange={handleChange}
          required
        />
      </div>

      {/* Test Type */}
      <div>
        <label>Test Type:</label>
        <select name="testType" value={formData.testType} onChange={handleChange}>
          <option value="Full Test">Full Test</option>
          <option value="Section Test">Section Test</option>
          <option value="Chapter Test">Chapter Test</option>
          <option value="Quiz">Quiz</option>
        </select>
      </div>

      {/* Number of Questions */}
      <div>
        <label>Number of Questions:</label>
        <input
          type="number"
          name="numberOfQuestions"
          value={formData.numberOfQuestions}
          onChange={handleChange} required
        />
      </div>

      {/* Marks */}
      <div>
        <label>Marks:</label>
        <input
          type="number"
          name="marks"
          value={formData.marks}
          onChange={handleChange} required
        />
      </div>

      {/* Time */}
      <div>
        <label>Time (in minutes):</label>
        <input
          type="number"
          name="time"
          value={formData.time}
          onChange={handleChange}  required
        />
      </div>

      {/* Date */}
      <div>
        <label>Date:</label>
        <input
          type="date"
          name="date"
          value={formData.date}
          onChange={handleChange} 
        />
      </div>

      {/* Language (Checkboxes) */}
      <div>
        <label>Language:</label>
        <div>
          <label>
            <input
              type="checkbox"
              value="English"
              checked={formData.language.includes("English")}
              onChange={handleCheckboxChange} 
            />
            English
          </label>
        </div>
        <div>
          <label>
            <input
              type="checkbox"
              value="Hindi"
              checked={formData.language.includes("Hindi")}
              onChange={handleCheckboxChange}
            />
            Hindi
          </label>
        </div>
     
      </div>

      {/* Syllabus */}
      <div>
        <label>Syllabus:</label>
        <input
          type="text"
          name="syllabus"
          value={formData.syllabus}
          onChange={handleChange} required
        />
      </div>

      {/* Submit Button */}
      <button type="submit">Create Test</button>
    </form>
  );
};

export default TestListForm;
