import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import axios from "axios";
import "./AddUniExam.css";
import apiurl from "../apiUrl";

const examTypes = ["Previous Exam", "Guess Paper", "Entrance Exam"];
const examNames = ["B.A", "B.Sc", "B.Com"];
const semesters = {
  "B.A": ["1st", "2nd", "3rd"],
  "B.Sc": ["1st", "2nd", "3rd", "4th"],
  "B.Com": ["1st", "2nd", "3rd", "4th", "5th", "6th"]
};
const years = Array.from({ length: 8 }, (_, i) => (2017 + i).toString());

const AddUniExam = () => {
  const { university } = useParams();
  const [activeSection, setActiveSection] = useState("upload");
  const [formData, setFormData] = useState({
    examType: "",
    examName: "",
    semester: "",
    year: "",
    file: null,
    subjectName: "",
  });
  const [modifySearch, setModifySearch] = useState({
    examType: "",
    examName: "",
    semester: "",
  });
  const [searchResults, setSearchResults] = useState([]);

  // Handles form data changes
  const handleChange = (e) => {
    const { name, value, files } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: files ? files[0] : value,
      ...(name === "examType" && { examName: "", semester: "", year: "" }),
      ...(name === "examName" && { semester: "", year: "" }),
      ...(name === "year" && { semester: "" }),
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!university) {
      alert("University name is missing in the URL.");
      return;
    }

    const uploadData = new FormData();
    uploadData.append("examType", formData.examType);
    uploadData.append("examName", formData.examName);
    uploadData.append("semester", formData.semester);
    uploadData.append("year", formData.year);
    uploadData.append("file", formData.file);
    uploadData.append("university", university);
    uploadData.append("subjectName", formData.subjectName);

    try {
      // Retrieve token from localStorage
      const token = localStorage.getItem("token");
    
      // Check if the token is present
      if (!token) {
        const response = await axios.post(
          `${apiurl}/unidata/addexamfile`, 
          uploadData, 
          {
            headers: {
              Authorization: `Bearer ${token}`, // Attach token to the request header
            },
          }
        );
        if (response.status === 201) {
          alert("File uploaded successfully!");
        } else if (response.status === 409) {
          alert("This file already exists.");
        } else {
          alert(`Unexpected response: ${response.data.message || "No message available"}`);
        }
        
      }else{
        alert("You must be logged in to Add a file.");

      }
    
    
      // Handle different response status codes
    
    } catch (error) {
      // Handle errors with specific checks for error status
      if (error.response) {
        if (error.response.status === 409) {
          alert("This file already exists.");
        } else {
          alert(`Unexpected error: ${error.response.data.message || "No message available"}`);
        }
      } else {
        console.error("Error uploading file:", error); // Log detailed error information
        alert("An error occurred while uploading the file.");
      }
    }
    
  };

  const handleModifySearch = async () => {
    try {
        const response = await axios.get(`${apiurl}/unidata/modifyfile`, {
            params: { ...modifySearch, university },
        });

        // Log the entire response for debugging
        console.log("Modify Search Response:", response.data);

        // Extract 'files' from the response or fallback to an empty array
        const files = response.data?.files || [];

        // If no files are returned, show an alert or handle gracefully
        if (files.length === 0) {
            alert(response.data.message || "No files found for the given search criteria.");
        } else {
            setSearchResults(files);
        }
    } catch (error) {
        console.error("Error fetching data:", error);
        alert("An error occurred while fetching search results.");
    }
};


const handleDownload = async (filename) => {
  try {
    console.log(`Downloading: ${filename}`);
    const response = await fetch(`${apiurl}/uni/download/${filename}`, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error(`Failed to download file: ${response.statusText}`);
    }

    const blob = await response.blob();
    const url = URL.createObjectURL(blob);

    // Create a temporary link to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = filename;
    document.body.appendChild(link);
    link.click();

    // Clean up resources
    document.body.removeChild(link);
    URL.revokeObjectURL(url);

    console.log(`${filename} downloaded successfully!`);
  } catch (error) {
    console.error("Error downloading file:", error);
  }
};

  
  

  const handleDelete = async (fileId) => {
    try {
      // Retrieve the token from localStorage or wherever it's stored
      const token = localStorage.getItem("token"); 
  
      // Check if the token is present
      if (token) {
        const response = await axios.delete(
          `${apiurl}/unidata/remove/${university}/${fileId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`, // Attach token to the request header
            },
          }
        );
        
        if (response.status === 200) {
          alert("File deleted successfully!");
          setSearchResults((prevResults) => prevResults.filter((file) => file.id !== fileId));
        }
      }else{
        
        alert("You must be logged in to delete a file.");
      }
  
      // Send the DELETE request with the Authorization header
  
    } catch (error) {
      console.error("Error deleting file:", error);
      alert("An error occurred while deleting the file.");
    }
  };

  const toUniverName = (str)=>{
    return str
    .split(' ') // Split string into words
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize first letter of each word
    .join(' '); // Join words back into a string
  }
  

  return (
    <div>
      <h1>{toUniverName(university.replaceAll('_', ' '))} Exam Manage</h1>

      {/* Header Section */}
      <div className="header-tabs">
        <button
          className={activeSection === "upload" ? "active" : ""}
          onClick={() => setActiveSection("upload")}
        >
          Upload
        </button>
        <button
          className={activeSection === "modify" ? "active" : ""}
          onClick={() => setActiveSection("modify")}
        >
          Modify
        </button>
      </div>

      {/* Conditional Rendering */}
      {activeSection === "upload" && (
        <section>
          <h2>Upload Exam File</h2>
          <form onSubmit={handleSubmit} encType="multipart/form-data">
            <div>
              <label>Exam Type:</label>
              <select
                name="examType"
                value={formData.examType}
                onChange={handleChange}
                required
              >
                <option value="">Select Exam Type</option>
                {examTypes.map((type, index) => (
                  <option key={index} value={type}>{type}</option>
                ))}
              </select>
            </div>
            <div>
              <label>Exam Name:</label>
              <select
                name="examName"
                value={formData.examName}
                onChange={handleChange}
                required
                disabled={!formData.examType}
              >
                <option value="">Select Exam Name</option>
                {examNames.map((name, index) => (
                  <option key={index} value={name}>{name}</option>
                ))}
              </select>
            </div>
            <div>
              <label>Year:</label>
              <select
                name="year"
                value={formData.year}
                onChange={handleChange}
                required
                disabled={!formData.examName}
              >
                <option value="">Select Year</option>
                {years.map((year, index) => (
                  <option key={index} value={year}>{year}</option>
                ))}
              </select>
            </div>
            <div>
              <label>Semester:</label>
              <select
                name="semester"
                value={formData.semester}
                onChange={handleChange}
                required
                disabled={!formData.year}
              >
                <option value="">Select Semester</option>
                {formData.examName && semesters[formData.examName].map((sem, index) => (
                  <option key={index} value={sem}>{sem}</option>
                ))}
              </select>
            </div>
            <div>
              <TextField
                fullWidth
                label="Subject Name"
                name="subjectName"
                value={formData.subjectName}
                onChange={handleChange}
                variant="outlined"
                margin="normal"
              />
            </div>
            <div>
              <label>File:</label>
              <input
                type="file"
                name="file"
                accept=".pdf,.docx"
                onChange={handleChange}
                required
              />
            </div>
            <button type="submit">Upload</button>
          </form>
        </section>
      )}

{activeSection === "modify" && (
  <section>
    <h2>Modify Exam Files</h2>
    <div>
      <label>Exam Type:</label>
      <select
        value={modifySearch.examType}
        onChange={(e) => setModifySearch({ ...modifySearch, examType: e.target.value })}
      >
        <option value="">Select Exam Type</option>
        {examTypes.map((type, index) => (
          <option key={index} value={type}>{type}</option>
        ))}
      </select>
    </div>
    <div>
      <label>Exam Name:</label>
      <select
        value={modifySearch.examName}
        onChange={(e) => setModifySearch({ ...modifySearch, examName: e.target.value })}
      >
        <option value="">Select Exam Name</option>
        {examNames.map((name, index) => (
          <option key={index} value={name}>{name}</option>
        ))}
      </select>
    </div>
    <div>
      <label>Semester:</label>
      <select
        value={modifySearch.semester}
        onChange={(e) => setModifySearch({ ...modifySearch, semester: e.target.value })}
      >
        <option value="">Select Semester</option>
        {modifySearch.examName &&
          semesters[modifySearch.examName].map((sem, index) => (
            <option key={index} value={sem}>{sem}</option>
          ))}
      </select>
    </div>
    <button onClick={handleModifySearch}>Search</button>

    {searchResults.length > 0 ? (
  searchResults.map((file, index) => (
    <div key={file._id} className="file-card">
      <div className="card-header">
        <span><strong>S.No:</strong> {index + 1} {file.examName} {file.examType}</span>
        {/* <span><strong>Exam Type:</strong> {file.examType}</span>
        <span><strong>Exam Name:</strong> {file.examName}</span> */}
      </div>

      <div className="card-body">
        <span> {file.subjectName || ""} {file.semester} sem. {file.year}</span>
        {/* <span><strong>Semester:</strong> {file.semester}</span> */}
      </div>

      <div className="card-actions">
      <button className="downloadBtn" onClick={() => handleDownload(file.fileName) }>Download</button>
        <button className="deleteBtn"
          onClick={() => {
            if (window.confirm("Are you sure you want to delete this file?")) {
              handleDelete(file._id); // Trigger delete on confirmation
            }
          }}
        >
          Delete
        </button>
      </div>
    </div>
  ))
) : (
  <p>No files found. Please refine your search criteria.</p>
)}


  </section>
)}

    </div>
  );
};

export default AddUniExam;
