import React, { useState } from 'react';
import axios from 'axios';
import './addQuestStyle.css';
import apiurl from '../apiUrl.js';


const AddQuestionForm = ({ closeDialog, id, exam, examName, testType }) => {
  console.log(id, exam, examName, testType)
  const [allFormData, setAllFormData] = useState({
   
    examId: id,
    examName: examName,
    examType: testType,
  });
  const [questionId, setQuestionId] = useState('');
  const [subject, setSubject] = useState('');
  const [topic, setTopic] = useState('');
  const [difficulty, setDifficulty] = useState('');
  const [questionEn, setQuestionEn] = useState({ type: 'text', content: '' });
  const [questionHindi, setQuestionHindi] = useState({ type: 'text', content: '' });
  const [optionsEn, setOptionsEn] = useState([{ type: 'text', content: '' }]);
  const [optionsHindi, setOptionsHindi] = useState([{ type: 'text', content: '' }]);
  const [answer, setAnswer] = useState('');
 
  const [solutionEn, setSolutionEn] = useState({ type: 'text', content: '' });
const [solutionHindi, setSolutionHindi] = useState({ type: 'text', content: '' });


const [showDialog, setShowDialog] = useState(false); // Dialog visibility
const [dialogData, setDialogData] = useState({}); // Data to display in dialog

const [allformdata, setAllformdata] = useState({});


  const subjectsList = [
    { id: 1, name: 'Mathematics', topics: ['Algebra', 'Geometry'] },
    { id: 2, name: 'Science', topics: ['Physics', 'Chemistry'] },
    {id:3, name: 'History', topics: ["Gandhi Era", "Khilji Era"]}
  ];

  const resetFormFields = () => {
    setQuestionId('');
    setSubject('');
    setTopic('');
    setDifficulty('');
    setQuestionEn({ type: 'text', content: '' });
    setQuestionHindi({ type: 'text', content: '' });
    setOptionsEn([{ type: 'text', content: '' }]);
    setOptionsHindi([{ type: 'text', content: '' }]);
    setAnswer('');
    setSolutionEn({ type: 'text', content: '' });
    setSolutionHindi({ type: 'text', content: '' });
  };
  

  const handleOptionChange = (lang, index, e) => {
    const setter = lang === 'en' ? setOptionsEn : setOptionsHindi;
    setter((prev) =>
      prev.map((opt, i) =>
        i === index
          ? { ...opt, [e.target.name]: e.target.name === 'content' && e.target.type === 'file' ? e.target.files[0] : e.target.value }
          : opt
      )
    );
  };

  const addOption = (lang) => {
    const setter = lang === 'en' ? setOptionsEn : setOptionsHindi;
    setter((prev) => [...prev, { type: 'text', content: '' }]);
  };

  const removeOption = (lang, index) => {
    const setter = lang === 'en' ? setOptionsEn : setOptionsHindi;
    setter((prev) => prev.filter((_, i) => i !== index));
  };

  const handleQuestionChange = (lang, e) => {
    const setter = lang === 'en' ? setQuestionEn : setQuestionHindi;
    setter((prev) =>
      e.target.name === 'content' && e.target.type === 'file'
        ? { ...prev, content: e.target.files[0] }
        : { ...prev, [e.target.name]: e.target.value }
    );
  };

  const handleSolutionChange = (lang, e) => {
    const setter = lang === 'en' ? setSolutionEn : setSolutionHindi;
    setter((prev) =>
      e.target.name === 'content' && e.target.type === 'file'
        ? { ...prev, content: e.target.files[0] }
        : { ...prev, [e.target.name]: e.target.value }
    );
  };
  
 

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Construct the formData object
    
    
        // Create FormData to submit the form data
        const formData = new FormData();
        formData.append('examId', id);
        formData.append('exam', exam);
        formData.append('examName', examName);
        formData.append('testType', testType);
        formData.append('questionId', questionId);
        formData.append('subject', subject);
        formData.append('topic', topic);
        formData.append('difficulty', difficulty);
    
        // Append question data
        formData.append('questionEn[type]', questionEn.type);
        if (questionEn.type === 'image') {
          formData.append('questionEn[content]', questionEn.content); // Image file should be assigned here
        } else {
          formData.append('questionEn[content]', questionEn.content); // Text content
        }
    
        formData.append('questionHindi[type]', questionHindi.type);
        if (questionHindi.type === 'image') {
          formData.append('questionHindi[content]', questionHindi.content); // Image file should be assigned here
        } else {
          formData.append('questionHindi[content]', questionHindi.content); // Text content
        }
    
        // Append options data (same for both English and Hindi)
        optionsEn.forEach((opt, index) => {
          formData.append(`optionsEn[${index}][type]`, opt.type);
          if (opt.type === 'image') {
            formData.append(`optionsEn[${index}][content]`, opt.content); // Image file for options
          } else {
            formData.append(`optionsEn[${index}][content]`, opt.content); // Text content
          }
        });
    
        optionsHindi.forEach((opt, index) => {
          formData.append(`optionsHindi[${index}][type]`, opt.type);
          if (opt.type === 'image') {
            formData.append(`optionsHindi[${index}][content]`, opt.content); // Image file for options
          } else {
            formData.append(`optionsHindi[${index}][content]`, opt.content); // Text content
          }
        });

        formData.append('answer', answer);  // Make sure this is properly appended
    
        // Append solution data
        formData.append('solutionEn[type]', solutionEn.type);
        if (solutionEn.type === 'image') {
          formData.append('solutionEn[content]', solutionEn.content); // Image file for solution
        } else {
          formData.append('solutionEn[content]', solutionEn.content); // Text content
        }
    
        formData.append('solutionHindi[type]', solutionHindi.type);
        if (solutionHindi.type === 'image') {
          formData.append('solutionHindi[content]', solutionHindi.content); // Image file for solution
        } else {
          formData.append('solutionHindi[content]', solutionHindi.content); // Text content
        }
    
        const extractedData = {};
        formData.forEach((value, key) => {
          extractedData[key] = value;
        });
      
        // Store the plain object in state
        setAllformdata(extractedData);

    for (let pair of formData.entries()) {
        // console.log(pair[0] + ": " + pair[1]);
      }

       // Convert FormData to an object for dialog display
  const dialogData = {};
  for (const [key, value] of formData.entries()) {
    if (value) {
      dialogData[key] = value;
    }
  }

  setDialogData(dialogData); // Save dialogData to state
  setShowDialog(true); // Show the dialog

    // Submit data
    // try {
    //   const response = await axios.post('http://91.108.111.8:5000/quest', formData, {
    //     headers: { 'Content-Type': 'multipart/form-data' },
    //   });
    //   console.log('Submission successful:', response.data);
    // } catch (error) {
    //   console.error('Error submitting form:', error);
    // }


  };


  const confirmSubmit = async () => {
    const token = localStorage.getItem("token"); // Retrieve the token from localStorage

if (token) {
  
  try {
    const response = await axios.post(`${apiurl}/data/addquestion`, allformdata, {
      headers: { 'Content-Type': 'multipart/form-data' ,
                   Authorization: `Bearer ${token}`, // Include the Authorization header
                },
    });
  
    
  
    // Check if the response is "OK"
    if (response.data.status === 'ok') {
      resetFormFields(); // Reset form fields
    }
  } catch (error) {
    console.error('Error submitting form:', error);
  }
}else{

  alert("Login required to perform this action.");
}
   
    setShowDialog(false); // Close the dialog
  };
  



  const cancelSubmit = () => {
    setShowDialog(false); // Close the dialog without submitting
  };



  return (
    <>
    <form onSubmit={handleSubmit} enctype= "multipart/form-data">
      <h2>Add Question</h2>

      {/* Question Metadata */}
      <div>
        <label>Question ID:</label>
        <input type="text" value={questionId} onChange={(e) => setQuestionId(e.target.value)} required />
      </div>
      <div>
        <label>Subject:</label>
        <select value={subject} onChange={(e) => setSubject(e.target.value)} required>
          <option value="">Select Subject</option>
          {subjectsList.map((subj) => (
            <option key={subj.id} value={subj.name}>
              {subj.name}
            </option>
          ))}
        </select>
      </div>
      <div>
        <label>Topic:</label>
        <select value={topic} onChange={(e) => setTopic(e.target.value)} required>
          <option value="">Select Topic</option>
          {subjectsList
            .find((subj) => subj.name === subject)
            ?.topics.map((topic) => (
              <option key={topic} value={topic}>
                {topic}
              </option>
            ))}
        </select>
      </div>

      {/* Question Fields */}
      {['en', 'hindi'].map((lang) => (
        <div key={lang}>
          <h3>Question ({lang === 'en' ? 'English' : 'Hindi Language'})</h3>
          <label>Type:</label>
          <select
            name="type"
            value={lang === 'en' ? questionEn.type : questionHindi.type}
            onChange={(e) => handleQuestionChange(lang, e)}
          >
            <option value="text">Text</option>
            <option value="image">Image</option>
          </select>
          <input
            type={lang === 'en' ? (questionEn.type === 'text' ? 'text' : 'file') : questionHindi.type === 'text' ? 'text' : 'file'}
            name="content"
            onChange={(e) => handleQuestionChange(lang, e)}
          />
        </div>
      ))}

      {/* Options */}
      {['en', 'hindi'].map((lang) => (
        <div key={lang}>
          <h3>Options ({lang === 'en' ? 'English' : 'Hindi Language'})</h3>
          {(lang === 'en' ? optionsEn : optionsHindi).map((option, index) => (
            <div key={index}>
              <label>Type:</label>
              <select
                name="type"
                value={option.type}
                onChange={(e) => handleOptionChange(lang, index, e)}
              >
                <option value="text">Text</option>
                <option value="image">Image</option>
              </select>
              <input
                type={option.type === 'text' ? 'text' : 'file'}
                name="content"
                onChange={(e) => handleOptionChange(lang, index, e)}
              />
              <button type="button" onClick={() => removeOption(lang, index)}>
              {`${index+1} Remove Option ${lang === 'en' ? 'English' : 'Hindi'}`}
              </button>
            </div>
          ))}
          <button type="button" onClick={() => addOption(lang)}>
          {`Add Option ${lang === 'en' ? 'English' : 'Hindi'}`}
          </button>
        </div>
      ))}

      {/* Answer */}
<div>
  <label>Answer (Index of Correct Option):</label>
  <input
    type="number"
    value={answer}
    onChange={(e) => setAnswer(e.target.value)}
    required
  />
</div>


      {/* Solution */}
      {['en', 'hindi'].map((lang) => (
  <div key={lang}>
    <h3>Solution ({lang === 'en' ? 'English' : 'Hindi Language'})</h3>
    <label>Type:</label>
    <select
      name="type"
      value={lang === 'en' ? solutionEn.type : solutionHindi.type}
      onChange={(e) => handleSolutionChange(lang, e)}
    >
      <option value="text">Text</option>
      <option value="image">Image</option>
    </select>
    <input
      type={lang === 'en' ? (solutionEn.type === 'text' ? 'text' : 'file') : solutionHindi.type === 'text' ? 'text' : 'file'}
      name="content"
      onChange={(e) => handleSolutionChange(lang, e)}
    />
  </div>
))}


      <button type="submit">Submit</button>
    </form>

    {showDialog && (
  <div className="dialog">
    <h3>Confirm Submission</h3>
    {dialogData['questionId'] && (
      <span>
        <strong>Question ID:</strong> {dialogData['questionId']}
      </span>
    )}
    {dialogData['subject'] && (
      <span>
        <strong>Subject:</strong> {dialogData['subject']}
      </span>
    )}
    {dialogData['topic'] && (
      <span>
        <strong>Topic:</strong> {dialogData['topic']}
      </span>
    )}
    {dialogData['answer'] && (
      <p>
        <strong>Answer:</strong> {dialogData['answer']}
      </p>
    )}
 {dialogData['questionEn[content]'] && (
    <div className="dialog-field">
      <strong>Question (English):</strong>
      {dialogData['questionEn[type]'] === 'text' ? (
        <span>{dialogData['questionEn[content]']}</span>
      ) : (
        <img
          src={URL.createObjectURL(dialogData['questionEn[content]'])}
          alt="Question (English)"
          style={{ maxWidth: '100px', height: 'auto' }}
        />
      )}
    </div>
  )}
 {dialogData['questionHindi[content]'] && (
    <div className="dialog-field">
      <strong>Question (Hindi):</strong>
      {dialogData['questionHindi[type]'] === 'text' ? (
        <span>{dialogData['questionHindi[content]']}</span>
      ) : (
        <img
          src={URL.createObjectURL(dialogData['questionHindi[content]'])}
          alt="Question (English)"
          style={{ maxWidth: '100px', height: 'auto' }}
        />
      )}
    </div>
  )}

 {/* Options (English) */}
{Object.keys(dialogData)
  .filter((key) => key.startsWith('optionsEn'))
  .map((key) => {
    // Extract the index from the key, e.g., optionsEn[0][type] -> 0
    const index = key.match(/\d+/)?.[0];
    
    return (
      <div key={key} className="dialog-field">
        {/* Display Option (English) */}
        <strong>{`Option (English) ${index ? `(${index})` : ''}:`}</strong>
        {dialogData[`${key.split('[')[0]}[type]`] === 'text' ? (
          <span>{dialogData[key]}</span> // Display text content
        ) : (
          typeof dialogData[key] === 'object' && dialogData[key] instanceof Blob ? (
            <img
              src={URL.createObjectURL(dialogData[key])} // Create image URL
              alt={`Option (English) ${index ? `(${index})` : ''}`}
              style={{ maxWidth: '100px', height: 'auto' }}
            />
          ) : (
            <span>Invalid image data</span> // Show fallback message if image is invalid
          )
        )}
      </div>
    );
  })}

{/* Options (Hindi) */}
{Object.keys(dialogData)
  .filter((key) => key.startsWith('optionsHindi'))
  .map((key) => {
    // Extract the index from the key, e.g., optionsHindi[0][type] -> 0
    const index = key.match(/\d+/)?.[0];
    
    return (
      <div key={key} className="dialog-field">
        {/* Display Option (Hindi) */}
        <strong>{`Option (Hindi) ${index ? `(${index})` : ''}:`}</strong>
        {dialogData[`${key.split('[')[0]}[type]`] === 'text' ? (
          <span>{dialogData[key]}</span> // Display text content
        ) : (
          typeof dialogData[key] === 'object' && dialogData[key] instanceof Blob ? (
            <img
              src={URL.createObjectURL(dialogData[key])} // Create image URL
              alt={`Option (Hindi) ${index ? `(${index})` : ''}`}
              style={{ maxWidth: '100px', height: 'auto' }}
            />
          ) : (
            <span>Invalid image data</span> // Show fallback message if image is invalid
          )
        )}
      </div>
    );
  })}

{dialogData['solutionEn[content]'] && (
  <div className="dialog-field">
    <strong>Solution (English):</strong>
    {dialogData['solutionEn[type]'] === 'text' ? (
      <span>{dialogData['solutionEn[content]']}</span>
    ) : (
      <img
        src={URL.createObjectURL(dialogData['solutionEn[content]'])}
        alt="Solution (English)"
        style={{ maxWidth: '100px', height: 'auto' }}
      />
    )}
  </div>
)}

{dialogData['solutionHindi[content]'] && (
  <div className="dialog-field">
    <strong>Solution (Hindi):</strong>
    {dialogData['solutionHindi[type]'] === 'text' ? (
      <span>{dialogData['solutionHindi[content]']}</span>
    ) : (
      <img
        src={URL.createObjectURL(dialogData['solutionHindi[content]'])}
        alt="Solution (Hindi)"
        style={{ maxWidth: '100px', height: 'auto' }}
      />
    )}
  </div>
)}



 
 

    <button onClick={confirmSubmit}>Confirm</button>
    <button onClick={cancelSubmit}>Cancel</button>
  </div>
)}




</>
  );
};

export default AddQuestionForm;
