import React, { useState } from "react";
import axios from "axios";
import "./jobListFormS.css"; // Importing the CSS file
import apiurl from '../apiUrl';

const JobListForm = () => {
  const [showDialog, setShowDialog] = useState(false);

  // State for category selection
  const [category, setCategory] = useState("Police");

  // State for form data
  const [formData, setFormData] = useState({
    id: "",
    createdate: "",
    lastdate:"",
    category: "Police", // Set default category
    recruitmentBoard: "",
    examName: "",
    noofpost: "",
    qualification: "",
    hotnews: "false", // Default value
    detailsLink: "",
  });

  const [message, setMessage] = useState("");

  const categories = ["Police", "Bank", "SSC", "Railway", "UPSC", "Defense"]; // Available categories

  // Handle input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle category change
  const handleCategoryChange = (e) => {
    const newCategory = e.target.value;
    setCategory(newCategory);
    setFormData({ ...formData, category: newCategory });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

    if (!token) {
      // If no token, open the login dialog
      alert("Before this action perform Login Required.")
    }else{

      try {
        const response = await axios.post(`${apiurl}/jobsa/addlist`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,  // Include token in Authorization header
          },
        });
        setMessage("Data submitted successfully!");
        setFormData({
          id: "",
          createdate: "",
          lastdate:"",
          category: "Police",
          recruitmentBoard: "",
          examName: "",
          noofpost: "",
          qualification: "",
          hotnews: "no", // Reset to default
          detailsLink: "",
        });
        console.log("Response from server:", response.data);
      } catch (error) {
        console.error("Error submitting data:", error);
        setMessage("Failed to submit data.");
      }
    }

  };

  return (
    <div>
      <button className="open-btn" onClick={() => setShowDialog(true)}>
        Add New Category
      </button>

      {showDialog && (
        <div className="modal" onClick={() => setShowDialog(false)}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <form onSubmit={handleSubmit} className="dynamic-form">
              <label>Category:</label>
              <select
                value={category}
                onChange={handleCategoryChange}
                className="form-select"
              >
                {categories.map((cat, index) => (
                  <option key={index} value={cat}>
                    {cat}
                  </option>
                ))}
              </select>

              <label>ID:</label>
              <input
                type="number"
                name="id"
                value={formData.id}
                onChange={handleInputChange}
              />

              <label>Create Date:</label>
              <input
                type="date"
                name="createdate"
                value={formData.createdate}
                onChange={handleInputChange}
              />

              <label>Last Date:</label>
              <input
                type="date"
                name="lastdate"
                value={formData.lastdate}
                onChange={handleInputChange}
              />

              <label>Recruitment Board:</label>
              <input
                type="text"
                name="recruitmentBoard"
                value={formData.recruitmentBoard}
                onChange={handleInputChange}
              />

              <label>Exam Name:</label>
              <input
                type="text"
                name="examName"
                value={formData.examName}
                onChange={handleInputChange}
              />

              <label>Number of Post:</label>
              <input
                type="number"
                name="noofpost"
                value={formData.noofpost}
                onChange={handleInputChange}
              />

              <label>Qualification:</label>
              <input
                type="text"
                name="qualification"
                value={formData.qualification}
                onChange={handleInputChange}
              />

              <label>Details Link:</label>
              <input
                type="url"
                name="detailsLink"
                value={formData.detailsLink}
                onChange={handleInputChange}
              />

              <label>Hot News:</label>
              <select
                name="hotnews"
                value={formData.hotnews}
                onChange={handleInputChange}
                className="form-select"
              >
                <option value="false">No</option>
                <option value="true">Yes</option>
              </select>

              <button type="submit">Submit</button>
            </form>

            <button className="close-btn" onClick={() => setShowDialog(false)}>
              Close
            </button>
          </div>
        </div>
      )}

      {message && <p>{message}</p>}
    </div>
  );
};

export default JobListForm;
