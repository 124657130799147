import React, { useState } from 'react';
import './jobAlertS.css';
import 'font-awesome/css/font-awesome.min.css';
import JobListForm from './jobListForm.js';

import GovtJob from './govtJob';


const tabs = ['Government Job', 'Results', 'Admit Card'];
const sidebarItems = ['Latest Job', 'Upcoming Job', 'Employment News'];
const filters = ['All', 'SSC', 'Bank', 'Police', 'Other All India', 'Statewise Job' ];
const states = [ 'Andhra Pradesh', 'Bihar', 'Delhi', 'Gujarat', 'Karnataka',]

const JobAlert = () => {
  const [selectedTab, setSelectedTab] = useState('Government Job'); // Default tab
  const [selectedFilter, setSelectedFilter] = useState('All'); // To handle selected filters
  const [selectedSidebarItem, setSelectedSidebarItem] = useState('Latest Job');
  const [isStatewiseOpen, setIsStatewiseOpen] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const toggleStatewise = (item) => {
    console.log("toogelStatewise called", item)
    setIsStatewiseOpen((prev) => !prev);
  };

  const handleSidebarItemClick = (item) => {
    setSelectedSidebarItem(item); // Update selected item
  };


  return (
    <div className="job-alert-container">
      {/* Header with Tabs */}
      <header className="job-alert-header">
        {tabs.map((tab) => (
          <div
            key={tab}
            className={`tab ${selectedTab === tab ? 'selected-tab' : ''}`}
            onClick={() => setSelectedTab(tab)}
          >
            {tab}
          </div>
        ))}

        <div className="search-box">
            <input 
            type="text" 
            placeholder="Search..." 
            className="search-input" 
            onChange={(e) => console.log('Search query:', e.target.value)} // Replace with a real handler
            />
            <button className="search-button">
            <i className="fa fa-search"></i> {/* Font Awesome icon */}
            </button>
        </div>

      </header>

      <res_header className="job-alert-res-header">
            {/* Dropdown for Tabs */}
            <div className="res-dropdown-container">
                <select
                className="res-tab-dropdown"
                value={selectedTab}
                onChange={(e) => setSelectedTab(e.target.value)}
                >
                {tabs.map((tab) => (
                    <option key={tab} value={tab}>
                    {tab}
                    </option>
                ))}
                </select>
            </div>

            {/* Search Box */}
            <div className="res-search-box">
                <input
                type="text"
                placeholder="Search..."
                className="res-search-input"
                onChange={(e) => console.log('Search query:', e.target.value)} // Replace with a real handler
                />
                <button className="res-search-button">
                <i className="fa fa-search"></i> {/* Font Awesome icon */}
                </button>
            </div>
        </res_header>

        


      <div className="job-alert-body">

      
        {/* Sidebar 1 */}
        
        <div className='job-alert-side-cont1'>
            
            <aside className="job-alert-sidebar">
                <h3>Category</h3>
                <ul>
                    {['Latest Job', 'Upcoming Job', 'Employment News'].map((item) => (
                    <li
                        key={item}
                        className={`sidebar-item ${selectedSidebarItem === item ? 'selected-sidebar-item' : ''}`}
                        onClick={() => handleSidebarItemClick(item)}
                    >
                        {item}
                        
                    </li>
                    ))}
                </ul>
            </aside>


            <aside className="res-job-alert-sidebar">
                <h3>Sidebar</h3>

                {/* Dropdown for Sidebar Items */}
                <select
                    className="res-sidebar-dropdown"
                    value={selectedSidebarItem}
                    onChange={(e) => handleSidebarItemClick(e.target.value)}
                >
                    {['Latest Job', 'Upcoming Job', 'Employment'].map((item) => (
                    <option className='res-sidebar-drop-option' key={item} value={item}>
                        {item}
                    </option>
                    ))}
                </select>
            </aside>

            {/* Sidebar 2 (Filter Section) */}
            <aside className="job-alert-filter-sidebar">
                <h3>Filter</h3>
                <ul>
                    {filters.map((filter) => (
                        <li
                            key={filter}
                            className={`filter-item ${selectedFilter === filter ? 'active-filter' : ''}`}
                            onClick={() => {
                                if (filter === 'Statewise Job') {
                                  toggleStatewise(filter);
                                } else {
                                  setSelectedFilter(filter);
                                }
                              }}
                        >
                            {filter}
                            {filter === 'Statewise Job' && (
                                <span className={`arrow ${isStatewiseOpen ? 'open' : ''}`}>
                                    {isStatewiseOpen ? '▲' : '▼'}
                                </span>
                            )}
                        </li>
                    ))}
                    {isStatewiseOpen && (
                        <ul className="state-list">
                            {states.map((state) => (
                                <li key={state} className="state-item" onClick={() => setSelectedFilter(state)}>
                                    {state}
                                </li>
                            ))}
                        </ul>
                    )}
                </ul>
            </aside>;

            <aside className="res-job-alert-filter-sidebar">
                <h3 className="res-filter-header">Filter</h3>
                <select
                    className="res-filter-dropdown"
                    value={selectedFilter}
                    onChange={(e) => {
                    const filter = e.target.value;
                    if (filter === 'Statewise Job') {
                        toggleStatewise();
                    } else {
                        setSelectedFilter(filter);
                        setIsStatewiseOpen(false); // Close state dropdown when another filter is selected
                    }
                    }}
                >
                    {filters.map((filter) => (
                    <option key={filter} value={filter}>
                        {filter}
                    </option>
                    ))}
                </select>

                {isStatewiseOpen && (
                    <select
                    className="res-state-list-dropdown"
                    onChange={(e) => setSelectedFilter(e.target.value)}
                    >
                    {states.map((state) => (
                        <option key={state} value={state}>
                        {state}
                        </option>
                    ))}
                    </select>
                )}
            </aside>


        </div>

        <main className="job-alert-content">

        <JobListForm showDialog={showDialog} setShowDialog={setShowDialog}/>

            <GovtJob  selectedFilter={selectedFilter}selectedSidebarItem={selectedSidebarItem} />
        </main>

      </div>


    </div>
  );
};

export default JobAlert;
