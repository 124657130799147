import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import UserImage from "../Images/web-user.jpg";
import { Link } from "react-router-dom";
import './navbarStyle.css';
import LoginSignup from "./loginSignUp.js";
import { FiAlignLeft } from "react-icons/fi";
import { FaRegWindowClose } from "react-icons/fa";
import logoImg from "../Images/examdiya logo.png";
import apiurl from '../apiUrl.js';

 const Navbar = () => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [showDropdown, setShowDropdown] = useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const [showExamDropdown, setShowExamDropdown] = useState(false);
    const [examSideDown, setExamSideDown] = useState(false);

    const userdownRef = useRef(null);
    const sidedownRef = useRef(null);

    const user = JSON.parse(localStorage.getItem('user') || '{}');
    const token = localStorage.getItem('token')
    
    const userName = user.name || "User";
   
    const profiImag = user?.profileImage 
    ? `${apiurl}/${user.profileImage.replace("..", "").replaceAll("\\", "/")}` 
    : UserImage;
    

    

    const handleDialogOpen = () => setIsDialogOpen(true);
    const handleDialogClose = () => setIsDialogOpen(false);

    const handleLogin = () => {
        setIsLoggedIn(true);
        handleDialogClose();
    };

    const handleUserClick = () => {
        if (isLoggedIn) {
            setShowDropdown((prev) => !prev);
        } else {
            handleDialogOpen();
        }
    };

    const handleClickOutside = (event) => {
        if (userdownRef.current && !userdownRef.current.contains(event.target)) {
            setShowDropdown(false);
        }
        if (sidedownRef.current && !sidedownRef.current.contains(event.target)) {
            setShowSidebar(false);
        }
    };

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        // Auto-login if token exists
        const token = localStorage.getItem('token');
        if (token) {
           
            axios.get(`${apiurl}/admin/auto-login`, {
                headers: { Authorization: `Bearer ${token}` }
            })
            .then(response => {
                
                if (response.data.user) {
                    setIsLoggedIn(true);
                } else {
                    localStorage.removeItem('token');
                    localStorage.removeItem('user');
                    setIsDialogOpen(true); 
                }
            })
            .catch(() => {
                localStorage.removeItem('token');
                localStorage.removeItem('user');
                setIsDialogOpen(true); // Show login dialog if token validation fails
            });
        }
    }, []);


 
    



    const handleLogout = () => {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setIsLoggedIn(false);
        setShowDropdown(false); // Close dropdown after logout
    };

    const menuList = [
        { label: userName, action: null },
        { label: "Phone Numb.", action: null },
        { label: "Setting", action: null },
        { label: "LogOut", action: handleLogout }
    ];

    const handleCBTClick = () => {
        setShowSidebar(!showSidebar);
        // Add any additional logic you need here
      };
      

    return (
        <>
            <header className="headerNav">
            <div
            className="sidebar-toggle-iconNav"
            style={{ backgroundColor: showSidebar ? 'red' : 'yellowgreen' }}
            onClick={() => setShowSidebar(!showSidebar)}
        >
            {showSidebar ? <FaRegWindowClose style={{ color: 'white' }} /> : <FiAlignLeft style={{ color: 'white' }} />}
        </div>
                <div className="logoNav">
                <Link to="/"><img className="logoimgNav" src={logoImg} />FreeTestBook</Link>
                </div>
                <div className="menu-link-headerNav">
                    <ul>
                        <li className="examOptNav" onClick={() => setShowExamDropdown(!showExamDropdown)}>
                            Exam
                            {showExamDropdown && (
                                <div className="sub-head-examListNav">
                                    <ul className="subExamListNav">
                                        {/* <li><Link to="/cbtexam">CBT Exam</Link></li> */}
                                        <Link to="/cbtexam"><li>CBT Exam</li></Link>
                                        <Link to="/university"><li>University Exam</li></Link>
                                        
                                    </ul>
                                </div>
                            )}
                        </li>
                        <li><a href="/currentaffair">Current Affair</a></li>
                        <li><Link to="/quiz">Quiz</Link></li>
                        <li><a href="/jobs">Job Alert</a></li>
                        <li><a href=".">Typing</a></li>
                        <li><a href=".">Notes</a></li>
                    </ul>
                </div>
                <div className="userNav" ref={userdownRef}>
                    <img 
                        src={profiImag} 
                        className="userLogoNav" 
                        onClick={handleUserClick} 
                        alt="User Logo" 
                    />
                    {showDropdown && isLoggedIn && (
                        <div className="userListNav" onMouseLeave={() => setShowDropdown(false)}>
                            <ul>
                                {menuList.map((item, index) => (
                                    <li key={index} onClick={item.action || null}>
                                        {item.label}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
            </header>

            {showSidebar && (
                <aside className="sidebarNav" ref={sidedownRef}>
                    <div className="side-menu-linkNav">
                        <ul>
                            <li onClick={() => setExamSideDown(!examSideDown)}>
                                Exam
                                {examSideDown && (
                                    <div className="sub-side-examListNav">
                                        <ul>
                                            <li><Link to="/cbtexam"  onClick={() => handleCBTClick()}>CBT Exam</Link></li>
                                            <li><Link to="/university">University Exam</Link></li>
                                        </ul>
                                    </div>
                                )}
                            </li>
                            <li><a href="/currentaffair">Current Affair</a></li>
                            <li><a href="/quiz">Quiz</a></li>
                            <li><a href="/jobs">Job Alert</a></li>
                            <li><a href=".">Typing</a></li>
                            <li><a href=".">Notes</a></li>
                        </ul>
                    </div>
                </aside>
            )}

            <LoginSignup open={isDialogOpen} onClose={handleDialogClose} onLogin={handleLogin} />
        </>
    );
};

export default Navbar;
