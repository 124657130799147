import {React, useState, useEffect} from "react";
import "./jobDetailS2.css";
import { useLocation } from 'react-router-dom';
import apiurl from "../apiUrl";





const JobDetail = () => {
  const location = useLocation();
  const jobDetails = location.state?.jobDetails;
  
  
 
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [jobData, setJobData] = useState([])
  
  // const { vacancyDetails} = jobData;
  // Fetch job details from backend
  const fetchJobDetails = async () => {
    try {
      const response = await fetch(`${apiurl}/jobdetail/get`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ category:jobDetails.category, id:jobDetails._id }), // Send category and id in the request body
      });
      // console.log(response.json())
  
      if (!response.ok) {
        throw new Error('Error fetching job details');
      }else{

        const data = await response.json();
       
        
        setJobData(data);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };
  
    
  useEffect(() => {
    fetchJobDetails();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  console.log(jobData);

  // Extract unique keys from the first object in vacancyDetails for headings
  const tableHeaders = jobData.length > 0 && jobData[0].vacancyDetails.length > 0 
  ? Object.keys(jobData[0].vacancyDetails[0]) 
  : [];

  console.log(tableHeaders);
  const vacHeadL = tableHeaders.length;
  console.log(vacHeadL);

  return (
    <div className="job-deatil-cont-wrapper"> {/* Wrapper div to contain the entire returned content */}
      {jobData.length>0 ? jobData.map((data, i) => (
        <div className="job-deatil-cont" key={i}> {/* Added key prop to the wrapper div */}
          <header>
            <h1>{data.title} Recruitment {data.year} Apply Online. <br />Number of Vacancy {data.vacancy}</h1>
          </header>
    
          <div>
            <h3 className="subhead-span"><span style={{ color: 'green' }}>Name of the Post :-</span> {data.examName}</h3>
            <h3 className="subhead-span"><span style={{ color: 'green' }}>Total Vacancy :-</span> {data.vacancy}</h3>
            <h3 className="subhead-span"><span style={{ color: 'brown' }}>Brief Information:-</span> {data.briefInfo}</h3>
          </div>




          <table className="table-container">
  <thead>
    <tr>
      <th colSpan="4" className="table-header" style={{ backgroundColor: 'white' }}>
        <h3 className="job-title">{jobData[i].title}</h3> {/* Access title from the jobData object */}
        <h3 className="advt-number">{jobData[i].advtNo}</h3> {/* Access advtNo from the jobData object */}
      </th>
    </tr>

    {jobData[i].importantDates && jobData[i].importantDates.length > 0 ? (
  <tr className="vacancy-row">
    <th colSpan={vacHeadL} className="important-dates-header">
      <h3 className="important-dates-title">Important Dates</h3>
      {jobData[i].importantDates.map((item, index) => (
        <div key={index}>
          {/* Check if advertisement exists and has key/value */}
          {item.advertisement && item.advertisement.key && item.advertisement.value ? (
            <p className="important-dates-item" style={{fontSize: '20px', fontWeight: 'bold'}}>
              {item.advertisement.key}:{" "}
              <span className="value-text">
                {item.advertisement.value.split("-").reverse().join("-")}
              </span>
            </p>
          ) : (
            <p className="important-dates-item">No Advertisement Information</p>
          )}

          {/* Check if details exist */}
          {item.details && item.details.length > 0 ? (
  item.details.map((detail, detailIndex) => (
    <p key={detailIndex} className="important-dates-item">
      <span className="arrow">→</span> {/* Add arrow here */}
      {detail.key}:{" "}
      <span className="value-text">
        {detail.value.split("-").reverse().join("-")}
      </span>
    </p>
  ))
) : (
  <p className="important-dates-item">No additional details available</p>
)}

        </div>
      ))}
    </th>
  </tr>
) : null}




    {jobData[i].agelimit[0] ? <tr>
      <th colSpan={vacHeadL}className="important-dates-header">
        <h3 className="important-dates-title">Age {jobData[i].ageBasedOn ? `based on (${jobData[i].ageBasedOn.split("-").reverse().join("-")})` :'' }</h3>
        {jobData[i].agelimit.map((item, index) => (  // Loop through importantDates array
          <p key={index} className="important-dates-item">
            {item.key}: <span className="value-text">{item.value} </span>{/* Display key and value of important dates */}
          </p>
        ))}
      </th>
    </tr> :''}

    {jobData[i].fees[0] ? <tr className="vacancy-row">
      <th colSpan={vacHeadL} className="important-dates-header">
        <h3 className="important-dates-title">Fees</h3>
        {jobData[i].fees.map((item, index) => (  // Loop through importantDates array
          <p key={index} className="important-dates-item">
            {item.key}: <span className="value-text">{item.value.split("-").reverse().join("-")}</span> {/* Display key and value of important dates */}
          </p>
        ))}
      </th>
    </tr> : ''}

    {jobData[i].qualification[0] ? <tr className="vacancy-row">
      <th colSpan={vacHeadL} className="important-dates-header">
        <h3 className="important-dates-title">Qualification </h3>
        {jobData[i].qualification.map((item, index) => (  // Loop through importantDates array
          <p key={index} className="important-dates-item">
            <span>*</span> {item.key}: <span className="value-text">{item.value}</span> {/* Display key and value of important dates */}
          </p>
        ))}
      </th>
    </tr> : ''}

    <tr>
      <th colSpan={vacHeadL} className="student-head-row">
        <h3>Vacancy Details</h3>
      </th>
    </tr>

    <tr>
      {Object.keys(jobData[i].vacancyDetails[i]).map((header, index) => (  // Dynamically render headers based on the keys of vacancyDetails object
        <th className="vacancy-head-row vacancy-row" key={index}>{header}</th>
      ))}
    </tr>
  </thead>
  <tbody>
    {jobData[i].vacancyDetails.map((detail, index) => (  // Loop through vacancyDetails array
      <tr key={index} className="vacancy-row">
        {Object.keys(detail).map((key, cellIndex) => (  // Dynamically render the corresponding values
          <td key={cellIndex}>{detail[key]}</td>
        ))}
      </tr>
    ))}
  </tbody>
  <tbody>
    
  <tr><th style={{ height: '25px' }}></th></tr>
  <tr>
    <th colSpan={vacHeadL} className="table-imp-link">
      <h3>Important Links</h3>
    </th>
  </tr>
  </tbody>
  <tfoot>
    {jobData[i].links.map((link, index) => (  // Loop through links array
      <tr key={index} className="links-row">
        <td colSpan="2">{link.key}</td> 
        <td colSpan="2"><a href={link.value} target="_blank" rel="noopener noreferrer">Click Here</a> {/* Render the link (value) */}</td>
      </tr>
    ))}
  </tfoot>
</table>








        </div>
      )) : 'No Data Found'}
    </div>
  );
  
};

export default JobDetail;
