import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";

import Home from "./Components/home.js";
import Navbar from "./Components/Header/Navbar.js";

import CenterExam from "./Components/GovtExam/GovtExam.js";
import ExamTypeList from "./Components/ExamTypeList/ExamTypeList.js";

import QuestionsPage from "./Components/Questions/QuestionsPage.js";

import UniversityExam from "./Components/UniversityExam/UniversityExam.js";
import AddUniExam from "./Components/UniversityExam/AddUniExam.js";

import Quiz from "./Components/Quiz/quiz.js";

import CurrentAffair from './Components/CurrentAffair/currentAffair3.js';
import NewsPage from "./Components/CurrentAffair/newsPage.js";
import AddNews from "./Components/CurrentAffair/addNews.js";

import Typing from './Components/Typing/typing.js';

import JobAlert from "./Components/JobAlert/jobAlert.js";
import JobDetail from "./Components/Job Page/jobDeatil2.js";
import JobDetailForm from "./Components/Job Page/jobDetailForm3.js";

function App() {
  return (
    <Router>
      <MainContent />
    </Router>
  );
}


const MainContent =()=>{
  const location = useLocation();

  const showNavbar = location.pathname !== "/test";

  return (
      <>
        {showNavbar && <Navbar />}
          <Routes>
              <Route path="/"  element={<div style={{ marginTop: '3rem' }}><Home /></div>}  />

              <Route  path='/cbtexam' element={<div style={{ marginTop: '3rem' }}><CenterExam /></div>}   />
              <Route  path="/cbtexam/:exam/:examName" element={<div style={{ marginTop: '3rem' }}> <ExamTypeList /></div>} />

              <Route  path="/questionpage" element={<div style={{ marginTop: '3rem' }}> <QuestionsPage /></div>}  />

              <Route  path="/university" element={<div style={{ marginTop: '3rem' }}> <UniversityExam /></div>}  />
              <Route    path="/university/:university" element={<div style={{ marginTop: '3rem' }}> <AddUniExam /></div>}  />

              <Route    path="/quiz" element={<div style={{ marginTop: '3rem' }}> <Quiz /></div>}  />
              <Route path="quiz/:examName/question" element={<div style={{ marginTop: '3rem' }}> <QuestionsPage /></div>} />

              <Route    path="/currentaffair" element={<div style={{ marginTop: '3rem' }}> <CurrentAffair /></div>}  />
              <Route    path="/current-affair/add" element={<div style={{ marginTop: '3rem' }}> <AddNews /></div>}  />
              <Route    path="/currentaffair/:date" element={<div style={{ marginTop: '3rem' }}> <NewsPage /></div>}  />

              <Route    path="/jobs" element={<div style={{ marginTop: '3rem' }}> <JobAlert /></div>}  />
              <Route    path="/jobs/:examName/:id" element={<div style={{ marginTop: '3rem' }}> <JobDetail /></div>}  />
              <Route    path="/jobs/:examName/:id/add" element={<div style={{ marginTop: '3rem' }}> <JobDetailForm /></div>}  />

          </Routes>
      </>
  )
}

export default App;
