import React, { useEffect, useState, useRef } from "react";
import axios from 'axios';
import { useLocation } from "react-router-dom";
import AddQuestionForm from "./AddQuest.js";
import "./questionStyle.css";
import apiurl from "../apiUrl.js";

const QuestionsPage = () => {
  const { state } = useLocation();
  const { id, exam, examName, testType } = state;

  const [questions, setQuestions] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false); // Dialog state
  const questionRefs = useRef([]); // Refs for each question to scroll to
  const [activeQuestionIndex, setActiveQuestionIndex] = useState(null); // Track which question is active

  // Fetch data from backend
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await axios.get(`${apiurl}/data/getquestions`, {
          params: { id, exam, examName, testType },
        });
        
        setQuestions(response.data.questions); // Use only the questions part
      } catch (error) {
        console.error("Error fetching questions:", error);
      }
    };

    fetchQuestions();
  }, [id, exam, examName, testType]);

  // Scroll to a specific question and highlight it
  const scrollToQuestion = (index) => {
    setActiveQuestionIndex(index); // Set the active question index
    questionRefs.current[index]?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  };

  // Dialog toggle functions
  const openDialog = () => setIsDialogOpen(true);
  const closeDialog = () => setIsDialogOpen(false);
  

  const handleDeleteQ = async (qId) => {
    // Confirm before making the request
    
    const confirmed = window.confirm("Are you sure you want to delete this question?");
    if (!confirmed) return;

    const token = localStorage.getItem("token");

if (token) {
  
  try {
    const response = await axios.delete(`${apiurl}/data/dropquestion`, {
      params: { id: qId, exam, examName, testType },
      headers: {
        Authorization: `Bearer ${token}`,  // Include token in Authorization header
      },
    });
  
    if (response.status === 200) {
      // Show success alert
      alert("Question deleted successfully!");
    } else {
      alert("Failed to delete the question.");
    }
  } catch (error) {
    console.error("Error during deleting question:", error);
    alert("An error occurred while deleting the question.");
  }
} else {
  alert("Login Required before performing this action.");

}
  
  };
  

  return (
    <div style={{ display: "flex", minHeight: "100vh" }}>
      {/* Sidebar */}
      <div className="sidebarQuestPg">
        {questions.map((_, index) => (
          <div
            key={index}
            onClick={() => scrollToQuestion(index)} // Click on a sidebar item to scroll
            className={`sidebar-itemQuestPg ${activeQuestionIndex === index ? "active" : ""}`} // Highlight active question
          >
            {index + 1}
          </div>
        ))}
      </div>

      {/* Main Content */}
      <div className="main-contentQuestPg">
        {/* Header */}
        <div className="header">
          <button onClick={openDialog} className="add-question-btn">
            Add Question
          </button>
          <div className="exam-info">
  <span>
    <strong>Exam:</strong> {examName} ({exam})
  </span>
  <span style={{ marginLeft: '50px' }}>
    <strong>Test Type:</strong> {testType}
  </span>
</div>

        </div>

        {/* Questions List */}
        <div className="questionList">
          {questions.map((question, index) => (
            <div
              key={question._id}
              ref={(el) => (questionRefs.current[index] = el)} // Assign ref for scrolling
              className={`question-item ${activeQuestionIndex === index ? "highlight" : ""}`} // Add class to highlight the active question
            >
              <h4>Question {index + 1}</h4>
              

              {/* Display Question */}
              <p><strong>Question (English):</strong> {question.question.en.content}</p>
              <p><strong>Question (Hindi):</strong> {question.question.hindi.content || "No content available"}</p>

              {/* Display Solution */}
              <p><strong>Solution (English):</strong> {question.solution.en.content}</p>
              <p><strong>Solution (Hindi):</strong> {question.solution.hindi.content || "No content available"}</p>

              {/* Display Options */}
              <div>
                <strong>Options:</strong>
                {question.options.map((option, optionIndex) => (
                  <div key={option._id}>
                    <p><strong>Option {optionIndex + 1} (English):</strong> {option.en.content}</p>
                    <p><strong>Option {optionIndex + 1} (Hindi):</strong> {option.hindi.content || "No content available"}</p>
                  </div>
                ))}
              </div>

              {/* Display Answer */}
              <p><strong>Answer:</strong> {question.answer}</p>
              <button onClick={()=>handleDeleteQ(question._id)}>Delete Question {index + 1}</button>
            </div>
          ))}
        </div>
      </div>

      {/* Add Question Dialog */}
      {isDialogOpen && (
        <div className="dialog-overlay" onClick={closeDialog}>
          <div
            className="dialog-container"
            onClick={(e) => e.stopPropagation()} // Prevent overlay click from closing
          >
            <h3 style={{marginTop: "0%"}}>Add Question</h3>
          <AddQuestionForm closeDialog={closeDialog} id={id} exam={exam} examName={examName} testType={testType} />
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionsPage;
