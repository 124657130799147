import React from "react";
import "./newsPageStyle.css";

const newsData = [
    {
        id: 1,
        date: '',
        title: "News Title 1",
        description: "This news contains important information.",
        imageUrl: "https://via.placeholder.com/300x180",
        link: "#",
        details: [
            "Fought in 1757 between British East India Company and Nawab of Bengal.",
            "Marked the start of British rule in India.",
            "Key figures: Robert Clive, Siraj-ud-Daulah.",
        ],
        list: [
            { key: "Date", rate: "31", area:34 },
            { key: "Author", value: "John Doe", rate: "31", area:34  },
            { key: "Category", value: "Politics", area:33  },
            { key: "Category", value: "Politics", img: "have", rate: "31", area:34  },
        ]
        
    },
    {
        id: 2,
        title: "News Title 2",
        description: "Another news item with more details.",
        imageUrl: "https://via.placeholder.com/300x180",
        link: "#",
        list: [
            { key: "Date", value: "2024-12-02" },
            { key: "Author", value: "Jane Smith" },
            { key: "Category", value: "Technology" },
        ],
    },
    { id: 3, title: "News Title 3", description: "Brief description of news 3.", imageUrl: "https://via.placeholder.com/300x180", link: "#" },
    { id: 4, title: "News Title 4", description: "Brief description of news 4.", imageUrl: "https://via.placeholder.com/300x180", link: "#" },
];



const NewsPage = () => {
    return (
        <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
      {newsData.map((item) => (
        <div
          key={item.id}
          style={{
            border: "1px solid #ddd",
            borderRadius: "5px",
            width: "300px",
            padding: "15px",
            boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <img
            src={item.imageUrl}
            alt={item.title}
            style={{ width: "100%", borderRadius: "5px" }}
          />
          <h3>{item.title}</h3>
          <p>{item.description}</p>
          {item.details && (
            <ul>
              {item.details.map((detail, idx) => (
                <li key={idx}>{detail}</li>
              ))}
            </ul>
          )}
          {item.list && (
            <div>
              <h4>Details</h4>
              {/* Get all unique keys from the list array */}
              <table style={{ width: "100%", borderCollapse: "collapse" }}>
                <thead>
                  <tr>
                    <th style={{ border: "1px solid #ddd", padding: "8px" }}>S.No</th>
                    {/* Dynamically generate table headers */}
                    {[
                      ...new Set(
                        item.list.flatMap((entry) => Object.keys(entry))
                      ),
                    ].map((key) => (
                      <th
                        key={key}
                        style={{
                          border: "1px solid #ddd",
                          padding: "8px",
                          textTransform: "capitalize",
                        }}
                      >
                        {key}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {item.list.map((row, rowIndex) => {
                    const allKeys = [
                      ...new Set(
                        item.list.flatMap((entry) => Object.keys(entry))
                      ),
                    ];
                    return (
                      <tr key={rowIndex}>
                        <td
                          style={{
                            border: "1px solid #ddd",
                            padding: "8px",
                            textAlign: "center",
                          }}
                        >
                          {rowIndex + 1}
                        </td>
                        {allKeys.map((key, colIndex) => (
                          <td
                            key={colIndex}
                            style={{ border: "1px solid #ddd", padding: "8px" }}
                          >
                            {row[key] || ""}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
          <a href={item.link} style={{ color: "#007bff", textDecoration: "none" }}>
            Read more
          </a>
        </div>
      ))}
    </div>
    );
};

export default NewsPage;
