import React, { useState } from 'react';
import './jobDetailFormS3.css';
import { useLocation } from 'react-router-dom';
import apiurl from '../apiUrl';

const JobDetailForm = ({ jobData }) => {
  const location = useLocation();
  const jobDetails = location.state?.jobDetails;



  const [formState, setFormState] = useState({
      importantDates: [
          {
            advertisement: { key: "Advertisement", value: "Advt No. 11/2024" },
            details: [
              { key: "Date of Publication of Advertisement", value: "02-08-2024" },
              { key: "Starting Date For Apply Online", value: "05-08-2024" },
            ],
          }
        ],
    id:"", listId: jobDetails._id,
    hid:jobDetails.hid,
    category:jobDetails.category,
    examName: jobDetails.examName || '',
    year: jobDetails.createdate.split('-')[0] || '',
    vacancy: jobDetails.noofpost || '',
    postdate: jobDetails.createdate || '',
    lastdate: jobDetails.lastdate || '',
    qualiBasedOn : '',
    qualification: [{ [`${jobDetails.qualification}`]: '' }] || [],
    briefInfo: '',
    title: '',
    advtNo: '',
    ageBasedOn:'',
    agelimit: [{ 'Minimum Age Limit:': '' }, { 'Maximum Age Limit': '' }],
    fees: [{ 'For General/ OBC/ EWS Candidates:': '' }, { 'For SC/ ST/ PwBD/ ESM/DESM Candidates': '' }],
    // importantDates: [{ 'Starting Date for Apply Online & Payment of Fee:': "" }, { 'Last Date to Apply Online & Payment of Fee:': '' }],
    vacancyDetails: [{'Name of Exam': ''}],
    links: [{ 'Apply Online': 'http://' }, { Notification: "http://" }],
  });
  
  // Separate state variables for new key-value pairs
  const [newKey, setNewKey] = useState('');
  const [newValue, setNewValue] = useState('');
  
  const [newKeyAgeLimit, setNewKeyAgeLimit] = useState('');
  const [newValueAgeLimit, setNewValueAgeLimit] = useState('');
  
  const [newKeyFees, setNewKeyFees] = useState('');
  const [newValueFees, setNewValueFees] = useState('');
  
  const [newKeyQualification, setNewKeyQualification] = useState('');
  const [newValueQualification, setNewValueQualification] = useState('');
  
  const [newKeyVacancyDetail, setNewKeyVacancycDetail] = useState([]);
  const [newValueVacancyDetail, setNewValueVacancyDetail] = useState([]);

  const [language, setLanguage] = useState('English'); // Default language is English

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
  };

   // Handle advertisement input change
   const handleAdvtChange = (advtIndex, field, value) => {
    const updatedFormData = [...formState];
    updatedFormData[advtIndex].advertisement[field] = value;
    setFormState(updatedFormData);
  };

  const handleDetailChange = (advtIndex, detailIndex, field, value) => {
    const updatedFormData = [...formState];
    updatedFormData[advtIndex].details[detailIndex][field] = value;
    setFormState(updatedFormData);
  };

 // Add a new key-value pair in details
 const handleAddDetail = (advtIndex) => {
    setFormState((prevState) => {
      const updatedImportantDates = [...prevState.importantDates];
      updatedImportantDates[advtIndex].details.push({ key: "", value: "" });
      return { ...prevState, importantDates: updatedImportantDates };
    });
  };
  

   // Remove a key-value pair in details
   const handleRemoveDetail = (advtIndex, detailIndex) => {
    setFormState((prevState) => {
      const updatedImportantDates = [...prevState.importantDates];
      updatedImportantDates[advtIndex].details.splice(detailIndex, 1);
      return { ...prevState, importantDates: updatedImportantDates };
    });
  };
  

  // Add a new advertisement
  const handleAddAdvertisement = () => {
    setFormState((prevState) => ({
      ...prevState,
      importantDates: [
        ...prevState.importantDates,
        { advertisement: { key: "Advertisement", value: "" }, details: [] },
      ],
    }));
  };
  
  

  // Remove an entire advertisement
  const handleRemoveAdvertisement = (advtIndex) => {
    setFormState((prevState) => {
      const updatedImportantDates = [...prevState.importantDates];
      updatedImportantDates.splice(advtIndex, 1);
      return { ...prevState, importantDates: updatedImportantDates };
    });
  };
  
  // Handle array changes
  const handleArrayChange = (index, key, value, section) => {
    const updatedSection = [...formState[section]];
    const existingEntry = Object.keys(updatedSection[index])[0];
  
    // Update the formState
    updatedSection[index] = { [key]: value };
    setFormState({ ...formState, [section]: updatedSection });
  
    // Update newKeyVacancyDetail and newValueVacancyDetail if applicable
    if (section === "vacancyDetails") {
      const updatedKeys = [...newKeyVacancyDetail];
      const updatedValues = [...newValueVacancyDetail];
      updatedKeys[index] = key;
      updatedValues[index] = value;
      setNewKeyVacancycDetail(updatedKeys);
      setNewValueVacancyDetail(updatedValues);
    }
  };
  
  // Handle adding a new key-value pair
  const handleAddField = (section, key, value) => {
    if (key && value) {
      const newItem = { [key]: value };
  
      // Update the formState
      setFormState({
        ...formState,
        [section]: [...formState[section], newItem],
      });
  
      // Update newKeyVacancyDetail and newValueVacancyDetail
      if (section === "vacancyDetails") {
        setNewKeyVacancycDetail([...newKeyVacancyDetail, key]);
        setNewValueVacancyDetail([...newValueVacancyDetail, value]);
      }
    } else {
      alert("Please enter both a key and a value.");
    }
  };
  
  // Handle removing the last key-value pair
  const handleRemoveField = (section) => {
    const updatedSection = formState[section].slice(0, -1);
  
    // Update the formState
    setFormState({ ...formState, [section]: updatedSection });
  
    // Update newKeyVacancyDetail and newValueVacancyDetail
    if (section === "vacancyDetails") {
      setNewKeyVacancycDetail(newKeyVacancyDetail.slice(0, -1));
      setNewValueVacancyDetail(newValueVacancyDetail.slice(0, -1));
    }
  };
  
 
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

   // Handle input changes for both advertisements and details
   const handleInputChange = (advtIndex, field, value, detailIndex = null) => {
    setFormState((prevState) => {
      const updatedDates = [...prevState.importantDates];
      if (detailIndex !== null) {
        updatedDates[advtIndex].details[detailIndex][field] = value;
      } else {
        updatedDates[advtIndex].advertisement[field] = value;
      }
      return { ...prevState, importantDates: updatedDates };
    });
  };




  
  

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");

    if (!token) {
        
        alert("Login Require before add new Advertisement.")
    }
    else {

        try {
          const response = await fetch(`${apiurl}/jobdetaila/add`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`, // Add Authorization header
              },
            body: JSON.stringify(formState),
          });
      
          if (!response.ok) {
            throw new Error("Failed to submit form");
          }
      
          const data = await response.json();
          console.log("Form submitted successfully:", data);
          alert("Form submitted successfully!");
        } catch (error) {
          console.error("Error submitting form:", error);
          alert("Error submitting form");
        }
    }
  
  };
  

  return (
    <div className="job-detail-container">
      <form className="job-detail-form" onSubmit={handleSubmit} >
        <h1 className="form-heading">Edit Job Details</h1>

         {/* Language Select Dropdown */}
         <label className="form-label">
          Select Language:
          <select
            name="language"
            value={language}
            onChange={handleLanguageChange}
            className="form-select"
          >
            <option value="English">English</option>
            <option value="Hindi">Hindi</option>
          </select>
        </label>

        <label className="form-label">
          Job Title:
          <input
            type="text"
            name="title"
            value={formState.title}
            onChange={handleChange}
            className="form-input"
          />
        </label>

        <label className="form-label">
          Advertisement Number:
          <input
            type="text"
            name="advtNo"
            value={formState.advtNo}
            onChange={handleChange}
            className="form-input"
          />
        </label>

        <label className="form-label">
          Exam Name:
          <input
            type="text"
            name="examName"
            value={formState.examName}
            onChange={handleChange}
            className="form-input"
          />
        </label>

        <label className="form-label">
          Recruitment Year:
          <input
            type="number"
            name="year"
            value={formState.year}
            onChange={handleChange}
            className="form-input"
          />
        </label>

        <label className="form-label">
          Total Vacancy:
          <input
            type="number"
            name="vacancy"
            value={formState.vacancy}
            onChange={handleChange}
            className="form-input"
          />
        </label>

        <label className="form-label">
          Brief Information:
          <textarea
            name="briefInfo"
            rows="4"
            value={formState.briefInfo}
            onChange={handleChange}
            className="form-textarea"
          />
        </label>


{/* Important Dates Section */}


{/* <div>
<h3>Important Dates</h3>
{formState.importantDates.map((item, index) => {
  const [key, value] = Object.entries(item)[0];
  return (
    <div key={index} className='key-value-entry'>
      <input type="text" value={key} readOnly />
      <input
        type="date"
        value={value}
        onChange={(e) => handleArrayChange(index, key, e.target.value, 'importantDates')}
      />
      <button type="button" onClick={() => handleRemoveField('importantDates')}>Remove</button>
    </div>
  );
})}
<div className='key-value-entry'>
  <input type="text" placeholder="Key" value={newKey} onChange={(e) => setNewKey(e.target.value)} />
  <input type="date" placeholder="Value" value={newValue} onChange={(e) => setNewValue(e.target.value)} />
  <button type="button" onClick={() => handleAddField('importantDates', newKey, newValue)}>Add</button>
</div>
</div> */}


<div>
      <h1>Dynamic Form</h1>
      {formState.importantDates.length > 0 && (
        <div>
          {formState.importantDates.map((advt, advtIndex) => (
            <div
              key={advtIndex}
              style={{ border: "1px solid #ccc", padding: "10px", marginBottom: "20px" }}
            >
              <h2>Advertisement</h2>
              <input
                type="text"
                placeholder="Key"
                value={advt.key}
                onChange={(e) =>
                  handleInputChange(advtIndex, "key", e.target.value)
                }
                style={{ marginRight: "10px", width: "45%" }}
              />
              <input
                type="text"
                placeholder="Value"
                value={advt.value}
                onChange={(e) =>
                  handleInputChange(advtIndex, "value", e.target.value)
                }
                style={{ marginRight: "10px", width: "45%" }}
              />
              <h3>Details</h3>
              {advt.details.map((detail, detailIndex) => (
                <div key={detailIndex} style={{ marginBottom: "10px" }}>
                  <input
                    type="text"
                    placeholder="Key"
                    value={detail.key}
                    onChange={(e) =>
                      handleInputChange(advtIndex, "key", e.target.value, detailIndex)
                    }
                    style={{ marginRight: "10px", width: "45%" }}
                  />
                  <input
                    type="text"
                    placeholder="Value"
                    value={detail.value}
                    onChange={(e) =>
                      handleInputChange(advtIndex, "value", e.target.value, detailIndex)
                    }
                    style={{ marginRight: "10px", width: "45%" }}
                  />
                  <button
                    type="button"
                    onClick={() => handleRemoveDetail(advtIndex, detailIndex)}
                    style={{ backgroundColor: "red", color: "white" }}
                  >
                    Remove Detail
                  </button>
                </div>
              ))}
              <button
                type="button"
                onClick={() => handleAddDetail(advtIndex)}
                style={{ marginBottom: "10px", backgroundColor: "green", color: "white" }}
              >
                Add Detail
              </button>
              <button
                type="button"
                onClick={() => handleRemoveAdvertisement(advtIndex)}
                style={{ backgroundColor: "darkred", color: "white" }}
              >
                Remove Advertisement
              </button>
            </div>
          ))}
        </div>
      )}
      <button
        type="button"
        onClick={handleAddAdvertisement}
        style={{ backgroundColor: "blue", color: "white" }}
      >
        Add Advertisement
      </button>
    </div>





<label className="form-label">
          Age Based On:
          <input
            type="date"
            name="ageBasedOn"
            value={formState.ageBasedOn}
            onChange={handleChange}
            className="form-input"
          />
        </label>

{/* Age Limit Section */}
<div>
<h3>Age Limit</h3>
{formState.agelimit.map((item, index) => {
  const [key, value] = Object.entries(item)[0];
  return (
    <div key={index} className='key-value-entry'>
      <input type="text" value={key} readOnly />
      <input
        type="number"
        value={value}
        onChange={(e) => handleArrayChange(index, key, e.target.value, 'agelimit')}
      />
      <button type="button" onClick={() => handleRemoveField('agelimit')}>Remove</button>
    </div>
  );
})}
<div className='key-value-entry'>
  <input type="text" placeholder="Key" value={newKeyAgeLimit} onChange={(e) => setNewKeyAgeLimit(e.target.value)} />
  <input type="number" placeholder="Value" value={newValueAgeLimit} onChange={(e) => setNewValueAgeLimit(e.target.value)} />
  <button type="button" onClick={() => handleAddField('agelimit', newKeyAgeLimit, newValueAgeLimit)}>Add</button>
</div>
</div>

{/* Fees Section */}
<div>
<h3>Fees</h3>
{formState.fees.map((item, index) => {
  const [key, value] = Object.entries(item)[0];
  return (
    <div key={index} className='key-value-entry'>
      <input type="text" value={key} readOnly />
      <input
        type="text"
        value={value}
        onChange={(e) => handleArrayChange(index, key, e.target.value, 'fees')}
      />
      <button type="button" onClick={() => handleRemoveField('fees')}>Remove</button>
    </div>
  );
})}
<div className='key-value-entry'>
  <input type="text" placeholder="Key" value={newKeyFees} onChange={(e) => setNewKeyFees(e.target.value)} />
  <input type="text" placeholder="Value" value={newValueFees} onChange={(e) => setNewValueFees(e.target.value)} />
  <button type="button" onClick={() => handleAddField('fees', newKeyFees, newValueFees)}>Add</button>
</div>
</div>

{/* Qualification Section */}
<label className="form-label">
          Qualification Based On:
          <input
            type="date"
            name="qualiBasedOn"
            value={formState.qualiBasedOn}
            onChange={handleChange}
            className="form-input"
          />
        </label>


<div >
<h3>Qualification</h3>
{formState.qualification.map((item, index) => {
  const [key, value] = Object.entries(item)[0];
  return (
    <div key={index} className='key-value-entry'>
      <input type="text" value={key} readOnly />
      <input
        type="text"
        value={value}
        onChange={(e) => handleArrayChange(index, key, e.target.value, 'qualification')}
      />
      <button type="button" onClick={() => handleRemoveField('qualification')}>Remove</button>
    </div>
  );
})}
<div className='key-value-entry'>
  <input type="text" placeholder="Key" value={newKeyQualification} onChange={(e) => setNewKeyQualification(e.target.value)} />
  <input type="text" placeholder="Value" value={newValueQualification} onChange={(e) => setNewValueQualification(e.target.value)} />
  <button type="button" onClick={() => handleAddField('qualification', newKeyQualification, newValueQualification)}>Add</button>
</div>
</div>


<div>
  <h3 className="section-heading">Vacancy Details:</h3>

  {formState.vacancyDetails.map((row, rowIndex) => (
    <div key={rowIndex} className="dynamic-row">
      <h4>Row {rowIndex + 1}</h4>

      {Object.entries(row).map(([key, value], fieldIndex) => (
        <div key={fieldIndex} className="dynamic-field">
          <div className="key-value-entry1">
            {/* Input for Key */}
            <input
  type="text"
  value={key}
  onChange={(e) => {
    const updatedVacancyDetails = [...formState.vacancyDetails];
    const updatedRow = { ...updatedVacancyDetails[rowIndex] };

    // Update the key (rename existing key)
    const oldKey = Object.keys(updatedRow)[fieldIndex];
    const newKey = e.target.value;

    if (newKey && oldKey !== newKey) {
      updatedRow[newKey] = updatedRow[oldKey];
      delete updatedRow[oldKey];
      updatedVacancyDetails[rowIndex] = updatedRow;

      setFormState({ ...formState, vacancyDetails: updatedVacancyDetails });
    }
  }}
  placeholder="Key"
  className="dynamic-input"
/>

<input
  type="text"
  value={value}
  onChange={(e) => {
    const updatedVacancyDetails = [...formState.vacancyDetails];
    const updatedRow = { ...updatedVacancyDetails[rowIndex] };

    // Update the value
    const key = Object.keys(updatedRow)[fieldIndex];
    updatedRow[key] = e.target.value;
    updatedVacancyDetails[rowIndex] = updatedRow;

    setFormState({ ...formState, vacancyDetails: updatedVacancyDetails });
  }}
  placeholder="Value"
  className="dynamic-input"
/>


          </div>

          {/* Remove Field Button */}
          <button
            type="button"
            onClick={() => {
              const updatedVacancyDetails = [...formState.vacancyDetails];
              delete updatedVacancyDetails[rowIndex][key];
              setFormState({ ...formState, vacancyDetails: updatedVacancyDetails });
            }}
            className="remove-btn"
          >
            Remove Field
          </button>
        </div>
      ))}

      
      {/* Add New Field Button */}
      <button
  type="button"
  onClick={() => {
    const updatedVacancyDetails = [...formState.vacancyDetails];
    const newFieldKey = `key${Object.keys(updatedVacancyDetails[rowIndex]).length + 1}`;
    updatedVacancyDetails[rowIndex] = {
      ...updatedVacancyDetails[rowIndex],
      [newFieldKey]: "",
    };
    setFormState({ ...formState, vacancyDetails: updatedVacancyDetails });
  }}
  className="add-btn"
>
  Add Field
</button>



      {/* Remove Row Button */}
      <button
        type="button"
        onClick={() => {
          const updatedVacancyDetails = formState.vacancyDetails.filter((_, i) => i !== rowIndex);
          setFormState({ ...formState, vacancyDetails: updatedVacancyDetails });
          setNewKeyVacancycDetail(newKeyVacancyDetail.filter((_, i) => i !== rowIndex));
          setNewValueVacancyDetail(newValueVacancyDetail.filter((_, i) => i !== rowIndex));
        }}
        className="remove-btn"
      >
        Remove Row
      </button>
    </div>
  ))}

  {/* Add New Row Button */}
  <button
  type="button"
  onClick={() => {
    setFormState({
      ...formState,
      vacancyDetails: [...formState.vacancyDetails, { key1: "" }],
    });

    setNewKeyVacancycDetail([...newKeyVacancyDetail, []]);
    setNewValueVacancyDetail([...newValueVacancyDetail, []]);
  }}
  className="add-btn"
>
  Add Row
</button>

</div>





    <h3 style={{marginTop:'10px'}}>Important Links</h3>
    <div className="links-section">
      {formState.links.map((link, index) => {
        // Extract key-value pair from each link object
        const [key, value] = Object.entries(link)[0]; // We assume each object has only one key-value pair
        return (
          <div key={index} className="link-row">
            <input
              type="text"
              value={key}
              readOnly
              className="link-input link-key"
            />
            <input
              type="text"
              value={value}
              onChange={(e) => {
                const updatedLinks = [...formState.links];
                updatedLinks[index] = { [key]: e.target.value }; // Update the value of the link
                setFormState({ ...formState, links: updatedLinks });
                
              }}
              className="link-input link-value"
            />
          </div>
        );
      })}

      {/* Button to add a new link */}
      <div className="link-row">
        <input
          type="text"
          value={newKey}
          placeholder="New Link Name"
          className="link-input link-key"
          onChange={(e) => {
            setNewKey(e.target.value); // Update the new key value
          }}
        />
        <input
          type="text"
          value={newValue}
          placeholder="New Link URL"
          className="link-input link-value"
          onChange={(e) => {
            setNewValue(e.target.value); // Update the new value
          }}
        />
        <button
          type="button"
          onClick={() => {
            if (newKey && newValue) {
              const newLink = { [newKey]: newValue }; // Create the new link object
              setFormState({
                ...formState,
                links: [...formState.links, newLink], // Add the new link to the state
              });
              setNewKey(""); // Reset the input for key
              setNewValue(""); // Reset the input for value
            } else {
              alert("Please enter both a link name and URL.");
            }
          }}
          className="add-link-btn"
        >
          Add New Link
        </button>
        
        {/* Button to remove the last link */}
        <button
          type="button"
          onClick={() => {
            if (formState.links.length > 0) {
              const updatedLinks = formState.links.slice(0, -1); // Remove last link
              setFormState({
                ...formState,
                links: updatedLinks,
              });
            } else {
              alert("No links to remove.");
            }
          }}
          className="remove-link-btn"
        >
          Remove Last Link
        </button>
      </div>
    </div>














        <button type="submit" className="submit-btn">
          Submit
        </button>
      </form>
    </div>
  );
};

export default JobDetailForm;
