import React, { useState, useEffect } from "react";
import axios from "axios";
import "./govtJobS.css";
import apiurl from "../apiUrl";
import { MdDeleteForever } from "react-icons/md";
import { useNavigate } from 'react-router-dom';

const newsList = [
  { title: "UGC NET Dec", action: "Apply Online" },
  { title: "NIACL Assistant" },
  { title: "ICG Asst Commandant" },
  { title: "RRC Apprentice", action: "Apply Online" },
  { title: "ITBP SI", action: "Apply Online" },
  { title: "NFR Apprentice", action: "Apply Online" },
  { title: "IDBI JAM & AAO", action: "Exam Date" },
  { title: "IBPS SO", action: "Admit Card" },
  { title: "SSC MTS", action: "Answer Key" },
];
const colors = [
  "#007bff", // Blue
  "#28a745", // Green
  "#ffc107", // Yellow
  "#dc3545", // Red
  "#6f42c1", // Purple
  "#fd7e14", // Orange
  "#17a2b8", // Teal
];

const GovtJob = ({ selectedFilter, selectedSidebarItem }) => {
  const [jobs, setJobs] = useState([]);
  const [message, setMessage] = useState("");
  const [data, setData] = useState([]);
  const [category, setCategory] = useState("All");

  const [topJobs, setTopJobs] = useState([]);

  const navigate = useNavigate();
  // Fetch job data based on the selected category
  const fetchJobs = async () => {
    try {
      const response = await axios.get(`${apiurl}/jobsa/getlist/all`);
      
      setJobs(response.data); // Set the fetched job data
      setMessage(""); // Clear error messages
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setMessage("Failed to fetch jobs.");
    }
  };

  const fetchTopJobs = async () => {
    try {
      const response = await axios.get(`${apiurl}/jobsa/gettoplist`);
      console.log("Fetched Data:", response.data);
      
      setTopJobs(response.data.data); // Set the fetched job data
      setMessage(""); // Clear error messages
    } catch (error) {
      console.error("Error fetching jobs:", error);
      setMessage("Failed to fetch jobs.");
    }
  };

  // Fetch jobs when the component mounts
  useEffect(() => {
    fetchJobs();
    fetchTopJobs();
  }, []);

  // Process data to group by category
  const groupedData = jobs.reduce((acc, job) => {
    const category = job.category.toLowerCase();
    if (!acc[category]) {
      acc[category] = [];
    }
    acc[category].push(job);
    return acc;
  }, {});

  // Determine which categories to display
  const filteredCategories =
    selectedFilter.toLowerCase() === "all"
      ? Object.keys(groupedData)
      : [selectedFilter.toLowerCase()];


    const deleteJob = async (cate, jobId) => {
      const token = localStorage.getItem("token");

      if (!token) {
        alert("Login Required before Perform this Action.")
      }else {

        try {
          
          await axios.delete(`${apiurl}/jobsa/droplist/${cate}/${jobId}`, {
            headers: {
              Authorization: `Bearer ${token}`,  // Include token in Authorization header
            },
          });
          // Filter out the deleted job
          // setData((prevData) => prevData.filter((job) => job._id !== jobId));
          alert("Job deleted successfully.");
        } catch (error) {
          console.error("Error deleting job:", error);
          alert("Failed to delete job.");
        }
      }
    };


    const deleteTopJob = async (jobId) => {
      const token = localStorage.getItem("token");
      if (!token) {
        alert("Login Required before Perform this Action.")
      }else{

        try {
          console.log("values are ", jobId)
          await axios.delete(`${apiurl}/jobsa/droptoplist/${jobId}`, {
            headers: {
              Authorization: `Bearer ${token}`,  // Include token in Authorization header
            },
          });
          // Filter out the deleted job
          // setData((prevData) => prevData.filter((job) => job._id !== jobId));
          alert("Job deleted successfully.");
        } catch (error) {
          console.error("Error deleting job:", error);
          alert("Failed to delete job.");
        }
      }
    };

  return (
    <div className="govt-job-container">
      {/* Display Latest News if All categories are selected */}
      

        {selectedFilter.toLowerCase() === "all" && (
          <div className="latest-news-box">
            <div className="latest-news-header">Top Jobs News</div>
            <div className="latest-news-content">
              <div className="news-grid">
                {topJobs.map((news, index) => (
                  <div
                    key={index}
                    className="news-item"
                    style={{
                      backgroundColor: colors[index % colors.length], // Random background color
                    }}
                  >
                    <div className="news-info"   onClick={() =>
                            navigate(`/jobs/${news.examName.replace(/\s+/g, "_").toLowerCase()}/${news.id}`, {
                              state: { jobDetails: news }, // Passing job details as state
                            })
                          }>
                      <div className="news-title">{news.examName}</div>
                      <div className="news-action">Apply Now</div>
                    </div>
                    {/* Add the delete icon */}
                    <MdDeleteForever className="news-icon" onClick={() => deleteTopJob(news._id)}/>
                  </div>
                ))}
              </div>
            </div>
            
          </div>
        )}


      {/* Job Listings */}
      <div className="government-jobs-table">
        {filteredCategories.map((category) => (
          <div key={category} className="job-category">
            <div className="job-category-head">
              <h2>
                {category.charAt(0).toUpperCase() + category.slice(1).toLowerCase()}
              </h2>
              <button className="view-all-button">View All</button>
            </div>
            <table className="table">
              <thead className="thead">
                <tr className="thead-data-row">
                  <th className="qulifi-sect">Post Date</th>
                  <th>Name of the Exam</th>
                  <th>Vacancy</th>
                  <th className="qulifi-sect">Qualification</th>
                  <th>Last Date</th>
                  <th>More Information</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody className="tbody">
                {groupedData[category]?.map((job, index) => (
                  <tr key={index} className="tbody-data-row">
                    <td className="qulifi-sect">{job.createdate.split('-').reverse().join('-')}</td>
                    <td>{job.examName}</td>
                    <td>{job.noofpost}</td>
                    <td className="qulifi-sect">{job.qualification}</td>
                    <td>{job.lastdate}</td>
                    <td>
                        <button
                          className="a-link" style={{marginRight:'7px'}}
                          onClick={() =>
                            navigate(`/jobs/${job.examName.replace(/\s+/g, "_").toLowerCase()}/${job.id}/add`, {
                              state: { jobDetails: job }, // Passing job details as state
                            })
                          }
                        >
                          Add Details
                        </button>
                        <button
                          className="a-link" style={{backgroundColor:'lightblue'}}
                          onClick={() =>
                            navigate(`/jobs/${job.examName.replace(/\s+/g, "_").toLowerCase()}/${job.id}`, {
                              state: { jobDetails: job }, // Passing job details as state
                            })
                          }
                        >
                          Get Details
                        </button>
                      </td>
                    <td> <button 
                      className="delete-button"
                      onClick={() => deleteJob(job.category, job._id)}
                    >
                      Delete Now
                    </button></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ))}
      </div>

      {/* Error Message */}
      {message && <div className="error-message">{message}</div>}
    </div>
  );
};

export default GovtJob;
