import React, { useState, useEffect } from "react";
import filter_logo from '../Images/filter-logo.png';
import apiUrl from '../apiUrl.js'
import { useNavigate } from 'react-router-dom';





import './quizStyle.css';

const examLists = {
  general :['Math', 'Englishh', 'Hindi', 'Physics', 'Chemistry', 'Biology', 'Geography', 'Polity', 'Economic', 'History'], 
  ssc: ['CGL', 'GD', 'CPO', 'CHSL'],
  banking: ['IBPS PO', 'IBPS Clerk', 'SBI PO', 'SBI Clerk'],
  teaching: ['CTET', 'TET', 'UPTET', 'REET']
};

const Quiz = () => {
  const [quizForm, setQuizForm] = useState({
    date: "",
    examType: "",
    examName: "",
    numQuestions: "",
    numMarks: "",
    examTime: "",
    syllabus: "",
    language: [],
  });
  const [examNameOptions, setExamNameOptions] = useState([]); 
  const [quizDialogVisible, setQuizDialogVisible] = useState(false);
  const [quizData, setQuizData] = useState([]);
  const [filteredQuizzes, setFilteredQuizzes] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [isBoxVisible, setIsBoxVisible] = useState(false);
  const [examType, setExamType] = useState("all");
  const [dateRange, setDateRange] = useState({ startDate: "", endDate: "" });

  const navigate = useNavigate();

  // Function to handle form input changes
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setQuizForm((prev) => ({
        ...prev,
        language: checked
          ? [...prev.language, value]
          : prev.language.filter((lang) => lang !== value),
      }));
    } else {
      setQuizForm((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Submit quiz form
  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");

if (token) {
  
  try {
    const response = await fetch(`${apiUrl}/quiz/list/create`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      Authorization: `Bearer ${token}`,
      body: JSON.stringify(quizForm),
    });
    const data = await response.json();
    if (response.ok) {
      alert("Quiz created successfully!");
      fetchQuizzes(); // Refresh quizzes
    } else {
      alert(data.message || "Failed to create quiz");
    }
  } catch (error) {
    console.error("Error creating quiz:", error);
  }
} else {
  
  alert("Login Required before performing this action.");
}

  };

  const fetchQuizzes = async (startDate, endDate, examType) => {
    try {
      const response = await fetch(
        `${apiUrl}/quiz/list/fetch?startDate=${startDate}&endDate=${endDate}&examType=${examType}`
      );
      const data = await response.json();
  
      // Sort data by date in descending order before setting states
      const sortedData = data.sort((a, b) => new Date(b.date) - new Date(a.date));
      setQuizData(sortedData);
      setFilteredQuizzes(sortedData);
    } catch (error) {
      console.error("Error fetching quizzes:", error);
    }
  };
  
  

  useEffect(() => {
    const today = new Date().toISOString().split("T")[0];
    const oneWeekAgo = new Date(new Date().setDate(new Date().getDate() - 7))
      .toISOString()
      .split("T")[0];

    setStartDate(oneWeekAgo);
    setEndDate(today);
    fetchQuizzes(oneWeekAgo, today, "all");
  }, []);

  const handleFetch = () => {
    if (startDate && endDate) {
      fetchQuizzes(startDate, endDate, examType);
    } else {
      alert("Please select both start and end dates!");
    }
  };

  // Filter quizzes
  const filterQuizzes = (type) => {
    const filtered = quizData
      .filter(
        (quiz) =>
          (!type || quiz.examType === type) &&
          (!dateRange.startDate || new Date(quiz.date) >= new Date(dateRange.startDate)) &&
          (!dateRange.endDate || new Date(quiz.date) <= new Date(dateRange.endDate))
      )
      .sort((a, b) => new Date(b.date) - new Date(a.date)); // Sort by date in descending order
  
    setFilteredQuizzes(filtered);
  };
  


  useEffect(() => {
    if (quizForm.examType) {
      setExamNameOptions(examLists[quizForm.examType] || []);
      setQuizForm((prev) => ({ ...prev, examName: "" })); // Reset examName
    }
  }, [quizForm.examType]);


  const handleAddQuestion = (quizId, examType, examName) => {
    console.log(quizId, examType, examName);
    const state = { id: quizId, exam: "quiz", examName: examType, testType: examName };
    navigate(`${examName}/question`, { state });
    console.log("Add question to quiz:", quizId);
  };
  
  
  
  const handleDeleteQuiz = async (quizId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this quiz?");
    
    if (confirmDelete) {
      const token = localStorage.getItem("token");
  
      if (token) {
        try {
          // Corrected the placement of the headers
          const response = await fetch(`${apiUrl}/quiz/list/delete/${quizId}`, {
            method: "DELETE",
            headers: {
              Authorization: `Bearer ${token}`, // Include token in Authorization header
              "Content-Type": "application/json", // Optional: Add content type if needed
            },
          });
  
          if (response.ok) {
            alert("Quiz deleted successfully!");
            fetchQuizzes(startDate, endDate, examType); // Refresh quizzes
          } else {
            alert("Failed to delete quiz");
          }
        } catch (error) {
          console.error("Error deleting quiz:", error);
        }
      } else {
        alert("Login Required before performing this action.");
      }
    }
  };
  
  

  return (
    <div className="quiz-body">
         <button className="create-quiz-btn" onClick={() => setQuizDialogVisible(!quizDialogVisible)} >Add New Quiz</button>
      {/* Quiz Creation Dialog */}
      {quizDialogVisible && (
        <div className="create-quiz-dialog">
          <i className="fa-regular fa-rectangle-xmark" onClick={() => setQuizDialogVisible(false)}
            style={{ color: "#cb628f" }} ></i>
          <form className="create-quiz-form" onSubmit={handleSubmit}>
            <label>Select Date:</label>
            <input  type="date"  name="date"  value={quizForm.date}  onChange={handleInputChange} required />

            <label>Select Exam Type:</label>
            <select
              name="examType"
              value={quizForm.examType}
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>
                Select Exam Type
              </option>
              <option value="general">General Exam</option>
              <option value="ssc">SSC Exam</option>
              <option value="banking">Banking Exam</option>
              <option value="teaching">Teaching Exam</option>
            </select>

            <label>Select Exam Name:</label>
            <select
              name="examName"
              value={quizForm.examName}
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>
                Select Exam
              </option>
              {examNameOptions.map((exam, index) => (
                <option key={index} value={exam}>
                  {exam}
                </option>
              ))}
            </select>

            <label>Number of Questions:</label>
            <input
              type="number"
              name="numQuestions"
              value={quizForm.numQuestions}
              onChange={handleInputChange}
            />

            <label>Number of Marks:</label>
            <input
              type="number"
              name="numMarks"
              value={quizForm.numMarks}
              onChange={handleInputChange}
            />

            <label>Exam Time (minutes):</label>
            <input
              type="number"
              name="examTime"
              value={quizForm.examTime}
              onChange={handleInputChange}
            />

            <label>Syllabus:</label>
            <textarea
              name="syllabus"
              rows="4"
              value={quizForm.syllabus}
              onChange={handleInputChange}
            ></textarea>

            <label>Select Language:</label>
            <div>
              <input
                type="checkbox"
                name="language"
                value="english"
                onChange={handleInputChange}
              />
              English
              <input
                type="checkbox"
                name="language"
                value="hindi"
                onChange={handleInputChange}
              />
              Hindi
            </div>

            <button type="submit" className="submit-btn">
              Create Quiz
            </button>
          </form>
        </div>
      )}

      {/* Quiz List */}
      <div className="quiz-show-box">


      <div className="quiz-list">
      <div className="quiz-list-head">
        <div className="list-select-box">
          <select className="list-type-select"  name="examType"  value={examType}
            onChange={(e) => setExamType(e.target.value)}
            >
              <option value="">Select Exam Type</option>
              <option value="general">General Exam</option>
              <option value="ssc">SSC Exam</option>
              <option value="banking">Banking Exam</option>
              <option value="teaching">Teaching Exam</option>
          </select>
        </div>

        <label>
          Start Date:
          <input
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </label>
        <label>
          End Date:
          <input
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </label>
        <button onClick={handleFetch}>Go</button>

        <div className="filter-box" onClick={() => setIsBoxVisible(!isBoxVisible)}>
          <img src={filter_logo} alt="Filter" />
          <div
            className="select-quiz-box"
            style={{ display: isBoxVisible ? "block" : "none" }}
          >
            <h3 onClick={() => filterQuizzes()}>All Quiz</h3>
            <h3 onClick={() => filterQuizzes("general")}>General</h3>
          </div>
        </div>
      </div>

      <div className="quiz-list-show">
            <h2>Quizzes</h2>
            {filteredQuizzes.length > 0 ? (
              <div className="quiz-card-container">
                {filteredQuizzes.map((quiz, index) => (
                  <div className="quiz-card" key={index}>
                    <div className="quiz-details">
                      <h2>Date: {quiz.date}</h2>
                      <h3> {quiz.examType} {quiz.examName}  : {quiz.syllabus}</h3>
                      <p>Total Question: {quiz.numQuestions}, Total Time: {quiz.examTime} Mint.</p>
                    </div>
                    <div className="quiz-actions">
                      <button className="add-question-btn" onClick={() => handleAddQuestion(quiz._id, quiz.examType, quiz.examName)}>Add Question</button>
                      <button className="delete-btn" onClick={() => handleDeleteQuiz(quiz._id)}>Delete</button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p>No quizzes found for the selected dates.</p>
            )}
          </div>



    </div>


      </div>
    </div>
  );
};

export default Quiz;
